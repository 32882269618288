<template>
  <div
    class="tableWrap"
    v-loading="listLoading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
  >
    <div
      class="table-top-bar"
      v-if="!selectedRowScopes.length"
    >
      <div class="leftBar">
        <el-dropdown
          split-button
          type="primary"
          size="small"
          @command="handleCommand"
          @click="handleCommandClick"
        >
          {{ adType }}
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="创建标准广告">创建标准广告</el-dropdown-item>
            <!-- <el-dropdown-item command="创建商品广告">创建商品广告</el-dropdown-item> -->
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div
        v-if="activeLevel == 'adset'"
        class="selectHasAd"
      >
        <el-radio-group
          v-model="hasAdActive"
          @input="labelChange"
        >
          <el-radio-button
            label="true"
            value=""
            >调户</el-radio-button
          >
          <el-radio-button
            label="false"
            value=""
            >查看组</el-radio-button
          >
        </el-radio-group>
      </div>
      <div class="midFilter">
        <div v-show="!filterShow">
          <el-dropdown
            trigger="click"
            @command="handleFilterCommand"
            placement="bottom"
            @visible-change="openFilter"
          >
            <el-button type="text">
              <el-image
                :src="filterIcon"
                style="width: 16px; height: 16px; margin-right: 5px; vertical-align: middle"
              ></el-image>
              <span style="color: #999">筛选</span></el-button
            >
            <el-dropdown-menu
              slot="dropdown"
              style="width: 460px"
              ref="filterDrop"
            >
              <div class="cusFilter">
                <div
                  class="cusFilterList"
                  v-if="initData"
                >
                  <div
                    class="cusFilterItem"
                    v-for="(filterObj, index) in cusFilterList"
                    :key="index"
                  >
                    <el-row
                      align="middle"
                      :gutter="5"
                    >
                      <el-col :span="6">
                        <el-select
                          size="mini"
                          v-model="filterObj.field"
                          popper-class="dropselect"
                          @change="fieldChange(cusFilterList, filterObj, index)"
                        >
                          <el-option
                            v-for="filterItem in initData.FilterFields"
                            :key="filterItem.keyName"
                            :label="filterItem.name"
                            :value="filterItem.keyName"
                            :disabled="
                              (cusFilterList.filter((v) => v.field == 'status').length > 0 &&
                                filterItem.keyName == 'status') ||
                              (cusFilterList.filter((v) => v.field == 'objective').length > 0 &&
                                filterItem.keyName == 'objective') ||
                              (cusFilterList.filter((v) => v.field == 'ad_label').length > 0 &&
                                filterItem.keyName == 'ad_label')
                            "
                          >
                            <span @click="showFilterDrop">{{ filterItem.name }}</span>
                          </el-option>
                        </el-select>
                      </el-col>
                      <el-col :span="5">
                        <el-select
                          size="mini"
                          v-model="filterObj.operator"
                          v-if="filterObj.field"
                          popper-class="dropselect"
                          :disabled="
                            initData.FilterFields.filter((v) => v.keyName == filterObj.field)[0].filtering.length == 1
                          "
                        >
                          <el-option
                            v-for="operators in initData.FilterFields.filter((v) => v.keyName == filterObj.field)[0]
                              .filtering"
                            :key="operators.key"
                            :label="operators.desc"
                            :value="operators.key"
                          >
                            <span @click="showFilterDrop">{{ operators.desc }}</span>
                          </el-option>
                        </el-select>
                        <!-- <el-select
                          size="mini"
                          v-model="filterObj.operator"
                          disabled
                          popper-class="dropselect"
                          v-else
                        >
                        </el-select> -->
                      </el-col>
                      <el-col :span="11">
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'objective'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in initData.CampaignObjective"
                            :key="filterItem.key"
                            :label="filterItem.desc"
                            :value="filterItem.key"
                          >
                            <span @click="showFilterDrop">{{ filterItem.desc }}</span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'status'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in initData.FilteringStatus"
                            :key="filterItem.key"
                            :label="filterItem.desc"
                            :value="filterItem.key"
                          >
                            <span @click="showFilterDrop">{{ filterItem.desc }}</span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'ad_label'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in labelList"
                            :key="filterItem.labelId"
                            :label="filterItem.name"
                            :value="filterItem.labelId"
                          >
                            <span @click="showFilterDrop">{{ filterItem.name }}</span>
                          </el-option>
                        </el-select>
                        <el-select
                          size="mini"
                          v-if="filterObj.field == 'open_schedule'"
                          v-model="filterObj.value1"
                          multiple
                          popper-class="dropselect"
                        >
                          <el-option
                            v-for="filterItem in openSchedule"
                            :key="filterItem.key"
                            :label="filterItem.desc"
                            :value="filterItem.key"
                          >
                            <span @click="showFilterDrop">{{ filterItem.desc }}</span>
                          </el-option>
                        </el-select>
                        <el-input
                          size="mini"
                          v-if="
                            filterObj.field != 'status' &&
                            filterObj.field != 'objective' &&
                            filterObj.field != 'ad_label' &&
                            filterObj.field != 'open_schedule'
                          "
                          v-model="filterObj.value"
                        ></el-input>
                      </el-col>
                      <el-col :span="2">
                        <i
                          class="el-icon-close"
                          v-if="cusFilterList.length > 1"
                          @click="deleFilterObj(index)"
                          style="margin-top: 5px"
                        ></i>
                      </el-col>
                    </el-row>
                  </div>
                </div>
                <div class="btnGroup">
                  <el-button
                    type="primary"
                    size="mini"
                    @click="saveFilterSearch"
                    >确定</el-button
                  >
                  <el-button
                    size="mini"
                    @click="addFilter(true)"
                    >添加</el-button
                  >
                </div>
              </div>
              <el-dropdown-item
                disabled
                divided
                style="font-size: 12px"
                >你的筛选组合</el-dropdown-item
              >
              <el-dropdown-item
                v-for="(filter, index) in filterList"
                :key="filter.id + index"
                :command="filter"
                :class="[filterId && filterId == filter.id ? 'active' : '']"
              >
                <div class="columnName">
                  <span>{{ filter.name }}</span>
                  <div>
                    <p>
                      <i
                        class="el-icon-delete"
                        v-if="filter.id"
                        @click.stop="deleFilterGroup(filter.id)"
                      ></i>
                    </p>
                  </div>
                </div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div
          class="filterWrap"
          v-show="filterShow"
        >
          <el-image
            :src="filterIcon"
            style="width: 14px; height: 14px"
          ></el-image>
          <div class="paramsFilters">
            <el-tag
              :key="i"
              v-for="(tags, i) in params.filtering"
              closable
              :disable-transitions="false"
              @close="deleFilterTag(tags, i)"
              size="mini"
              effect="dark"
              style="border-radius: 10px; font-size: 12px"
            >
              <div>
                <el-dropdown
                  trigger="click"
                  placement="bottom"
                >
                  <span style="color: #fff">{{
                    initData.FilterFields.filter((v) => v.keyName == tags.field)[0] | showName
                  }}</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-radio-group
                      v-model="tags.field"
                      @change="fieldChange(params.filtering, tags, i)"
                    >
                      <p
                        v-for="filterItem in initData.FilterFields"
                        :key="filterItem.keyName"
                        style="padding: 10px"
                      >
                        <el-radio
                          :label="filterItem.keyName"
                          :disabled="
                            (params.filtering.filter((v) => v.field == 'status').length > 0 &&
                              filterItem.keyName == 'status') ||
                            (params.filtering.filter((v) => v.field == 'objective').length > 0 &&
                              filterItem.keyName == 'objective') ||
                            (params.filtering.filter((v) => v.field == 'ad_label').length > 0 &&
                              filterItem.keyName == 'ad_label')
                          "
                        >
                          {{ filterItem.name }}
                        </el-radio>
                      </p>
                    </el-radio-group>
                  </el-dropdown-menu>
                </el-dropdown>
                <span style="margin: 0 5px">
                  <span v-if="tags.field == 'status' || tags.field == 'objective' || tags.field == 'ad_label'">{{
                    operatorName(initData.FilterFields.filter((v) => v.keyName == tags.field)[0], tags) || '包含'
                  }}</span>
                  <el-dropdown
                    trigger="click"
                    placement="bottom"
                    v-else
                  >
                    <span style="color: #fff">{{
                      operatorName(initData.FilterFields.filter((v) => v.keyName == tags.field)[0], tags) || '请选择'
                    }}</span>
                    <el-dropdown-menu slot="dropdown">
                      <el-radio-group
                        v-model="tags.operator"
                        @change="operatorChange"
                      >
                        <p
                          v-for="filterItem in showFiltering(params.filtering[i])"
                          :key="filterItem.key"
                          style="padding: 10px"
                        >
                          <el-radio :label="filterItem.key">{{ filterItem.desc }}</el-radio>
                        </p>
                      </el-radio-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                </span>
                <span>
                  <el-dropdown
                    trigger="click"
                    placement="bottom"
                    v-if="
                      tags.field == 'status' ||
                      tags.field == 'objective' ||
                      tags.field == 'ad_label' ||
                      tags.field == 'open_schedule'
                    "
                  >
                    <span style="color: #fff">{{ valueName(tags).join(',') || '请选择' }}</span>
                    <el-dropdown-menu
                      slot="dropdown"
                      style="max-height: 400px; overflow-y: auto"
                    >
                      <el-checkbox-group
                        v-model="tags.value1"
                        @change="valueChange"
                      >
                        <p
                          v-for="(checkbox, index) in tags.field == 'ad_label'
                            ? labelList
                            : tags.field == 'open_schedule'
                            ? openSchedule
                            : initData[tags.field == 'status' ? 'FilteringStatus' : 'CampaignObjective']"
                          :key="index"
                          style="padding: 10px"
                        >
                          <el-checkbox
                            :label="checkbox.labelId"
                            v-if="tags.field == 'ad_label'"
                            >{{ checkbox.name }}</el-checkbox
                          >
                          <el-checkbox
                            :label="checkbox.key"
                            v-else
                            >{{ checkbox.desc }}</el-checkbox
                          >
                        </p>
                      </el-checkbox-group>
                    </el-dropdown-menu>
                  </el-dropdown>
                  <span v-else>
                    <el-input
                      v-model="tags.value"
                      class="tagInput"
                      @change="valueChange"
                    ></el-input>
                  </span>
                </span>
              </div>
            </el-tag>
            <el-tag
              size="mini"
              style="border-radius: 10px; font-size: 12px; border: 1px dashed #409eff; cursor: pointer"
              @click="addTag"
            >
              <i class="el-icon-plus"></i>
              <span>添加筛选项</span>
            </el-tag>
          </div>
          <div class="rightIcon">
            <i
              class="el-icon-finished"
              @click="saveFilterShow = true"
            ></i>
            <i
              class="el-icon-circle-close"
              @click="closeMyFilter"
            ></i>
          </div>
        </div>
      </div>
      <div
        class="rightTools"
        :style="{ flex: searchInput ? '260px 0 0' : '100px 0 0' }"
      >
        <div class="searchInput">
          <el-input
            clearable
            placeholder="请输入内容"
            v-model="params.keyword"
            class="input-with-select"
            v-if="searchInput"
            @change="keywordChange"
          ></el-input>
          <el-button
            type="text"
            icon="el-icon-search"
            @click="params.keyword ? (searchInput = true) : (searchInput = !searchInput)"
          ></el-button>
        </div>
        <div class="tools">
          <div class="toolsItem">
            <el-tooltip
              class="item"
              effect="dark"
              content="加载细分"
              placement="top"
            >
              <el-popover
                placement="bottom"
                width="100"
                trigger="click"
                @show="showBreakdown"
              >
                <div>
                  <ul class="breakdownList">
                    <li
                      v-for="(item, index) in breakdownRulesArr"
                      :key="item.by"
                    >
                      <el-popover
                        placement="left"
                        width="100"
                        trigger="hover"
                        :tabindex="index"
                        offset="20"
                      >
                        <el-radio-group
                          v-model="breakdowms[item.by]"
                          class="toolsCheckTips"
                          @change="selectBreakdown"
                        >
                          <el-radio label="">无</el-radio>
                          <el-radio
                            :label="xifenItem.breakdowns.join(',')"
                            v-for="xifenItem in item.rules"
                            :key="xifenItem.value"
                            :disabled="
                              xifenItem.disabled || breakdownGroup.some((v) => xifenItem.disable_when.includes(v))
                            "
                          >
                            {{ xifenItem.title }}
                          </el-radio>
                        </el-radio-group>
                        <span slot="reference">{{ item.title }} <i class="el-icon-arrow-right"></i></span>
                      </el-popover>
                    </li>
                  </ul>
                </div>
                <el-button
                  slot="reference"
                  type="text"
                  class="el-icon-s-data"
                ></el-button>
              </el-popover>
            </el-tooltip>
          </div>
          <div class="toolsItem">
            <el-tooltip
              class="item"
              effect="dark"
              content="定制列"
              placement="top"
            >
              <el-dropdown
                trigger="click"
                @command="handleColumnCommand"
                placement="bottom"
              >
                <el-button
                  type="text"
                  class="el-icon-s-grid"
                ></el-button>
                <el-dropdown-menu
                  slot="dropdown"
                  style="width: 300px"
                >
                  <el-dropdown-item command="定制列组合">定制列组合</el-dropdown-item>
                  <el-dropdown-item
                    disabled
                    divided
                    style="font-size: 12px"
                    >你的列组合</el-dropdown-item
                  >
                  <el-dropdown-item
                    v-for="column in myCols.myColumnsArr"
                    :key="column.id"
                    :command="column"
                    :class="[myCols.myColumsId == column.id ? 'active' : '']"
                  >
                    <div class="columnName">
                      <span>{{ column.name }}</span>
                      <div v-if="!column.idefault">
                        <p>
                          <i
                            class="el-icon-edit"
                            @click.stop="editColumns(column)"
                          ></i>
                          <i
                            class="el-icon-delete"
                            @click.stop="deleColumns(column)"
                          ></i>
                        </p>
                      </div>
                    </div>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </el-tooltip>
          </div>
          <div class="downLoad">
            <down-load :downLoadParams="{ ...downLoadParams, ids: [] }"></down-load>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div
        class="toolsBar"
        v-if="selectedRowScopes.length"
      >
        <div class="toolsCon">
          <p>已选{{ selectedRowScopes.length }}项</p>
          <!-- <span style="margin-right: 15px">批量操作</span> -->
          <!-- <div class="toolsOne">
                        <span><i class="el-icon-video-play"></i></span>
                        <el-button size="mini" @click="setStatus(false,'ACTIVE')">启动</el-button>
                    </div>
                     <div class="toolsOne">
                        <span><i class="el-icon-video-pause"></i></span>
                        <el-button size="mini" @click="setStatus(false,'PAUSED')">暂停</el-button>
                    </div> -->
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'ACTIVE')"
              >启动</el-button
            >
          </div>

          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'PAUSED')"
              >暂停</el-button
            >
          </div>
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="handleEditCommand({ type: 'adsets' })"
              v-if="selectedRowScopes.filter((v) => v.level == 'adset').length"
              >编辑</el-button
            >
            <el-button
              type="primary"
              plain
              size="mini"
              @click="handleEditCommand({ type: 'editAds' })"
              v-if="selectedRowScopes.filter((v) => v.level == 'ad').length"
              >编辑</el-button
            >
          </div>
          <div class="operate-btn">
            <!-- <span><i class="el-icon-edit-outline"></i></span> -->
            <el-dropdown
              trigger="click"
              @command="handleEditCommand"
              placement="bottom"
            >
              <el-button
                type="primary"
                plain
                size="mini"
                >批量操作</el-button
              >
              <el-dropdown-menu
                slot="dropdown"
                style="width: 260px"
              >
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告系列</span>
                    <div>已选{{ selectedRowScopes.filter((v) => v.level == 'campaign').length }}项</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'campaign' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'campaign').length"
                  >修改预算与竞价</el-dropdown-item
                >
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告组</span>
                    <div>已选{{ selectedRowScopes.filter((v) => v.level == 'adset').length }}项</div>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item
                  :command="{ type: 'adset' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                  >修改预算与竞价</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{ type: 'adsetPix' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                  >批量修改像素</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{ type: 'adsetName' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'adset').length"
                  >批量修改广告组名称</el-dropdown-item
                >
                <!-- <el-dropdown-item
                  :command="{ type: 'adsets' }"
                  :disabled="
                    !selectedRowScopes.filter((v) => v.level == 'adset').length
                  "
                  >批量编辑广告组</el-dropdown-item
                > -->
                <el-dropdown-item
                  disabled
                  style="font-size: 12px"
                >
                  <div class="columnName">
                    <span>广告</span>
                    <div>已选{{ selectedRowScopes.filter((v) => v.level == 'ad').length }}项</div>
                  </div>
                </el-dropdown-item>
                <!-- <el-dropdown-item  :command="{type:'ad'}" :disabled="!selectedRowScopes.filter(v=>v.level == 'ad').length">修改广告创意</el-dropdown-item> -->
                <el-dropdown-item
                  :command="{ type: 'adName' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                  >批量修改广告名称</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{ type: 'adUrl' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                  >批量添加广告网域</el-dropdown-item
                >
                <el-dropdown-item
                  :command="{ type: 'editPages' }"
                  :disabled="!selectedRowScopes.filter((v) => v.level == 'ad').length"
                  >批量编辑广告主页</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="editCopyShow = true"
              >快速复制</el-button
            >
          </div>
          <div class="operate-btn">
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setStatus(false, 'DELETED')"
              >删除</el-button
            >
          </div>
          <div
            class="operate-btn"
            v-if="level !== 'ad'"
          >
            <el-button
              type="primary"
              plain
              size="mini"
              @click="creatAudiecnce"
              >创建类似受众</el-button
            >
          </div>
          <div
            class="operate-btn"
            v-show="level !== 'ad'"
          >
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setTimeHandle(1)"
              >定时开启</el-button
            >
          </div>
          <div
            class="operate-btn"
            v-show="level !== 'ad'"
          >
            <el-button
              type="primary"
              plain
              size="mini"
              @click="setTimeHandle(4)"
              >定时关闭</el-button
            >
          </div>
          <div
            class="operate-btn"
            v-show="level == 'campaign'"
          >
            <el-button
              type="primary"
              plain
              size="mini"
              @click="updateAI()"
              >AI助手更新推荐</el-button
            >
          </div>
        </div>
        <div class="close">
          <i
            class="el-icon-close"
            @click="clearIds"
          ></i>
        </div>
      </div>
    </div>
    <!--  v-loading="loading" -->
    <el-table
      :data="tableData"
      style="width: 100%"
      row-key="id"
      :lazy="lazy"
      :load="loadChildren"
      @selection-change="handleSelectionChange"
      border
      v-infinite-scroll="{ load: loadMore, distance: 0 }"
      :infinite-scroll-disabled="infiniteScrollDisabled"
      ref="table"
      @expand-change="expandChange"
      :default-sort="{ prop: 'effective_status', order: 'ascending' }"
      @sort-change="sortChange"
      show-summary
      :summary-method="getSummaries"
      stripe
      :cell-style="cellStyle"
      :header-cell-class-name="headerCellClassName"
      @row-click="rowClick"
      class="my-table"
    >
      <el-table-column type="selection"></el-table-column>

      <el-table-column
        prop="name"
        label="名称"
        width="360"
        sortable
        fixed
      >
        <template slot-scope="scope">
          <ad-cell-name
            :value="scope"
            :disabled="disAbled"
            @scope-row-changed="refreshTableRow(scope)"
            @openChart="openChart"
            @setStatus="setStatus"
            @copy="copyRow"
            @handle-edit-command="handleEditCommand"
            :level="level"
            @open-next-level="openNextLevel"
            @operationLog="operationLog"
          ></ad-cell-name>
        </template>
      </el-table-column>
      <el-table-column
        prop="effective_status"
        label="状态"
        width="130"
        sortable="custom"
      >
        <template #header>
          <div class="statusHeader">
            <span>状态</span>
            <div
              @click.stop
              style="display: inline-block"
            >
              <el-popover
                placement="bottom"
                width="120"
                trigger="click"
                v-if="initData.FilteringStatus"
              >
                <el-checkbox-group
                  v-model="checkList"
                  class="toolsCheckTips"
                  @change="selectStatus"
                >
                  <el-checkbox
                    :label="checkbox.key"
                    v-for="checkbox in initData.FilteringStatus"
                    :key="checkbox.key"
                    >{{ checkbox.desc }}
                  </el-checkbox>
                </el-checkbox-group>
                <i
                  slot="reference"
                  class="el-icon-discount"
                ></i>
              </el-popover>
            </div>
          </div>
        </template>

        <template
          slot-scope="scope"
          v-if="scope.row.effective_status"
        >
          <status
            :scope="scope"
            :initData="initData"
            @reviewScore="reviewScore"
            :level="level"
          ></status>
        </template>
      </el-table-column>
      <el-table-column
        prop="effective_status"
        label="是否关联商品"
        width="120"
        v-if="level == 'campaign'"
      >
        <template slot-scope="scope">
          <p v-if="scope.row.level == 'campaign'">
            <span v-if="scope.row.campaign_ref == 'NOT_REF'">
              <el-button
                size="mini"
                @click="relateSaleClick(scope)"
                >关联商品</el-button
              >
            </span>
            <span v-else-if="scope.row.campaign_ref == 'REF'">
              <span>已关联</span>
            </span>
            <span v-else-if="scope.row.campaign_ref == 'REFING'">
              <span>关联中</span>
            </span>
            <span v-else>
              <span>--</span>
            </span>
          </p>
          <p v-else>--</p>
        </template>
      </el-table-column>
      <el-table-column
        :prop="item.keyName"
        :label="item.name"
        v-for="(item, index) in fields.filter(
          (v) => v.keyName != 'status' && v.keyName != 'id' && v.keyName != 'name' && v.keyName != 'campaign_ref',
        )"
        :key="index"
        :width="
          item.keyName == 'budget'
            ? '200'
            : item.keyName == 'local_order'
            ? '120'
            : item.keyName == 'local_event'
            ? '180'
            : item.keyName == 'open_schedule'
            ? '280'
            : item.keyName == 'ad_label'
            ? '240'
            : item.keyName == 'page_name'
            ? '180'
            : item.name.length >= 8
            ? '180'
            : item.name.length >= 5
            ? '110'
            : item.width
        "
        :sortable="item.hasSort || manualSort(item) ? 'custom' : item.hasSort"
        :align="item.keyName == 'budget' || item.keyName == 'product_report' ? undefined : 'right'"
        header-align="left"
      >
        <template #header>
          <div
            class="statusHeader"
            v-if="item.keyName == 'open_schedule'"
          >
            <span>{{ item.name }}</span>
            <div
              @click.stop
              style="display: inline-block"
            >
              <el-popover
                placement="bottom"
                width="120"
                trigger="click"
              >
                <el-checkbox-group
                  v-model="open_schedule"
                  class="toolsCheckTips"
                >
                  <el-checkbox
                    v-for="item in openSchedule"
                    :label="item.key"
                    >{{ item.desc }}</el-checkbox
                  >
                </el-checkbox-group>
                <i
                  slot="reference"
                  class="el-icon-discount"
                ></i>
              </el-popover>
            </div>
          </div>
          <span v-else>
            <el-popover
              placement="bottom"
              :title="item.name"
              width="460"
              trigger="hover"
              :content="item.remark"
              :disabled="!item.remark"
            >
              <span slot="reference">{{ item.name }}</span>
            </el-popover>
          </span>
        </template>
        <template slot-scope="scope">
          <ad-cell-inline-edit-budget
            :value="scope"
            @scope-row-changed="refreshTableRow(scope)"
            :key="scope.row.level + scope.row.id + scope.row.name"
            v-if="item.keyName === 'budget'"
            :date-range="dateRange"
            @get-list="debouncedReloadTable"
            @settingComplete="settingComplete"
          ></ad-cell-inline-edit-budget>
          <!-- 竞价 -->
          <div v-else-if="item.keyName === 'bid_amount'">
            <bid-amount
              :value="scope"
              @scope-row-changed="refreshTableRow(scope)"
              :key="scope.row.level + scope.row.id + scope.row.name"
              @settingComplete="settingComplete"
              @get-list="debouncedReloadTable"
              :level="level"
            ></bid-amount>
          </div>
          <div v-else-if="item.keyName === 'product_report'">
            <!-- tab切换-商品经营分析/广告系列经营分析（默认广告系列经营分析）-->
            <adCellProductReport
              :adProductReport="scope.row.adProductReport"
              :level="scope.row.level"
              :campaignId="scope.row.campaign_id"
              :adAccountId="scope.row.account_id"
              :currency_sign="
                ($store.getters.currentAccountInfo && $store.getters.currentAccountInfo.currency.key) || 'USD'
              "
              :key="scope.row.level + scope.row.id + scope.row.name"
              platform="facebook"
              :dateRange="dateRange.dateRange"
            ></adCellProductReport>
          </div>

          <div v-else-if="item.keyName === 'local_order'">
            <local-order
              :row="scope.row"
              :id="scope.row.id"
              :level="level"
            ></local-order>
          </div>
          <div v-else-if="item.keyName === 'local_event'">
            <div v-if="scope.row.local_event">
              <p
                class="color9"
                style="height: 20px; line-height: 20px"
                v-for="(item, index) in scope.row.local_event"
                :key="index"
              >
                {{ item.eventTypeDesc }}(成本)：{{ item.eventCount }}({{ item.costPrice }})
              </p>
            </div>
            <div v-else>--</div>
          </div>
          <div v-else-if="item.keyName === 'open_schedule'">
            <div class="flex items-center">
              <div v-if="(scope.row.open_schedule && scope.row.open_schedule.length > 0) || scope.row.hasSchedule">
                <img
                  style="width: 16px; height: 16px"
                  src="@/assets/image/jiqiren.png"
                />
              </div>
              <div v-if="scope.row.open_schedule && scope.row.open_schedule.length > 0">
                <p
                  v-for="(item, index) in scope.row.open_schedule"
                  :key="scope.row.id + index"
                  class="open-schedule"
                >
                  <span
                    v-if="item && item.type == 1"
                    class="set-open"
                    >定时开启任务:{{ item.timeStr }}
                    <el-button
                      type="text"
                      size="mini"
                      @click.stop="cancelSet('task', scope.row, scope.$index, index, item.id)"
                      >取消</el-button
                    ></span
                  >
                  <span
                    v-if="item && item.type == 4"
                    class="set-open"
                    >定时关闭任务:{{ item.timeStr }}
                    <el-button
                      type="text"
                      size="mini"
                      @click.stop="cancelSet('task', scope.row, scope.$index, index, item.id)"
                      >取消</el-button
                    ></span
                  >
                  <span
                    v-if="item && item.type == 2"
                    class="budget-span"
                    >定时修改预算:{{ item.timeStr }}
                    <el-button
                      type="text"
                      size="mini"
                      @click.stop="cancelSet('budget', scope.row, scope.$index, index, item.id)"
                      >取消</el-button
                    ></span
                  >
                  <span
                    v-if="item && item.type == 2"
                    class="budget-span"
                    >预算金额：{{ item.budget }}</span
                  >
                  <span
                    v-if="item && item.type == 3"
                    class="budget-span"
                    >定时修改出价:{{ item.timeStr }}
                    <el-button
                      type="text"
                      size="mini"
                      @click.stop="cancelSet('bid', scope.row, scope.$index, index, item.id)"
                      >取消</el-button
                    ></span
                  >
                  <span
                    v-if="item && item.type == 3"
                    class="budget-span"
                    >出价：{{ item.bidAmount }}</span
                  >
                </p>
              </div>
              <span v-else> -- </span>
            </div>
          </div>
          <div v-else-if="item.keyName == 'ad_label'">
            <campaign-label
              :labelList="labelList"
              @getLabelList="getLabelList"
              :level="level"
              :info="scope"
              :index="scope.$index"
              :accountId="accountId"
              @scope-row-changed="refreshTableRow(scope)"
              :gimpUserId="userInfo.data.id"
            ></campaign-label>
          </div>
          <div v-else-if="item.keyName == 'page_name'">
            <page-name :row="scope.row"></page-name>
          </div>
          <div v-else-if="item.keyName == 'today_local_order'">
            <today-order :row="scope.row"></today-order>
          </div>
          <div v-else-if="item.keyName == 'gross_rate'">
            {{
              scope.row.adProductReport
                ? scope.row.adProductReport.grossMarginRate &&
                  scope.row.adProductReport.grossMarginRate.toFixed(2) + '%'
                : '--'
            }}
          </div>
          <span
            v-else
            v-html="formatedCellValueV2(scope.row, item)"
          ></span>
        </template>
      </el-table-column>
      <template slot="append">
        <div
          class="loadMore"
          @click="loadMoreClick"
          :style="loadText == '滚动/点击加载更多' ? 'cursor:pointer' : ''"
        >
          {{ loadText }}
        </div>
      </template>
    </el-table>
    <!-- 保存为我的筛选条件 -->
    <el-dialog
      title="保存查询条件"
      :visible.sync="saveFilterShow"
      width="500px"
    >
      <div>
        <el-form label-width="120px">
          <el-form-item label="查询条件名称">
            <el-input
              placeholder="请输入"
              v-model="filterName"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: right">
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button
              type="primary"
              @click="saveMyFilter"
              >保存</el-button
            >
            <el-button
              @click="
                saveFilterShow = false;
                filterName = '';
              "
              >取消</el-button
            >
          </span>
        </div>
      </div>
    </el-dialog>
    <!-- 定制列弹窗 -->
    <cus-column-dialog
      :visible="cusColumnVisible"
      :initCheckedColumns="initCheckedColumns"
      @close-dialog="cusColumnVisible = false"
      :name="myColumsName"
      @post-save-res="handleGetSaveRes"
      @post-cus-columns="handleGetCusColumns"
    ></cus-column-dialog>
    <!-- 图表抽屉 -->
    <chart-drawer
      :visible="drawers.chart.visible"
      @close-drawer="drawers.chart.visible = false"
      :currentRow="drawers.chart.currentRow"
      :list="tableData"
      :pageAfter="after"
      :pageSize="params.page_length"
      :total="adsCountObj[`${level}s`] || 0"
      :fields="fields"
      :defDateStart="params.date_start"
      :defDateEnd="params.date_end"
    ></chart-drawer>
    <!-- 复制[广告系列、广告组、广告 ]抽屉 -->
    <copy-drawer
      :visible="drawers.copy.visible"
      @close-drawer="drawers.copy.visible = false"
      :selected-row-scopes="drawers.copy.rowScopes"
      :level="drawers.copy.level"
      @reload-table="reloadTable"
    ></copy-drawer>
    <!-- 编辑广告组 -->
    <edit-ad-group
      :editGroupShow="editGroupShow"
      @close="close"
      :initData="initDataAdCreat"
      :id="editAdSetId"
      :accountId="accountId"
      :preview="false"
      @get-list="debouncedReloadTable"
      type="FB"
      :adsetScope="adsetScope"
    ></edit-ad-group>
    <!-- 编辑广告 -->
    <edit-ads
      :editAdsShow="editAdsShow"
      @close="close"
      :initData="initDataAdCreat"
      :id="editAdId"
      :accountId="accountId"
      :preview="false"
      @get-list="debouncedReloadTable"
      type="FB"
      :adScope="adScope"
    ></edit-ads>
    <!-- 编辑竞价预算 -->
    <edit-budget-price
      :editBudgetPriceShow="drawers.editBudgetPrice.visible"
      @close-drawer="drawers.editBudgetPrice.visible = false"
      :selectedRowScopes="drawers.editBudgetPrice.rowScopes"
      :level="drawers.editBudgetPrice.level"
      @refresh-table="refreshTablePrice"
    ></edit-budget-price>
    <!-- 关联商品 -->
    <related-goods
      :relateSale="relateSale"
      @close="close"
      :rowMsg="rowMsg"
      @getList="refreshTableRow"
      paltform="facebook"
    ></related-goods>
    <!-- 批量修改广告创意 -->
    <batch-edit-ads
      @close="close"
      :list="selectedRowScopes.filter((v) => v.level == 'ad')"
      :batchEditAds="batchEditAds"
      :initData="initDataAdCreat"
    ></batch-edit-ads>
    <!-- 创建广告 -->
    <edit-ads
      :editAdsShow="creatAdsShow"
      @close="close"
      :initData="initDataAdCreat"
      :id="creatAdParentId"
      :accountId="accountId"
      :preview="false"
      @get-list="debouncedReloadTable"
      type="FB"
      :adScope="adScope"
      :creat="true"
    ></edit-ads>
    <!-- 修改广告名称 -->
    <edit-ad-name
      :editNameShow.sync="editNameShow"
      @close="close"
      nameLevel="ad"
      :list="selectedRowScopes.filter((v) => v.level == 'ad')"
      @get-list="debouncedReloadTable"
      :date-range="dateRange"
    ></edit-ad-name>
    <!-- 修改广告名称 -->
    <edit-ad-name
      :editNameShow.sync="editAdsetNameShow"
      @close="close"
      nameLevel="adset"
      :list="selectedRowScopes.filter((v) => v.level == 'adset')"
      @get-list="debouncedReloadTable"
      :date-range="dateRange"
    ></edit-ad-name>
    <!-- 编辑广告组像素 -->
    <batch-edit-pixel
      :editPixelShow="editPixelShow"
      @close="close"
      :list="selectedRowScopes.filter((v) => v.level == 'adset')"
      @get-list="debouncedReloadTable"
    ></batch-edit-pixel>
    <!-- 批量编辑广告组 -->
    <batch-edit
      :batchEditShow.sync="batchEditShow"
      :list="selectedRowScopes.filter((v) => v.level == level)"
      :level="level"
      :initCreatAdData="initDataAdCreat"
      @get-list="debouncedReloadTable"
    ></batch-edit>
    <!-- 快速复制 -->
    <edit-copy
      :visible.sync="editCopyShow"
      @close-drawer="editCopyShow = false"
      :editRows="editCopyList"
      :level="level"
      @reload-table="reloadTable"
    ></edit-copy>
    <!-- 批量修改网域 -->
    <ad-url
      :editUrlShow="editUrlShow"
      @close="close"
      :list="selectedRowScopes.filter((v) => v.level == 'ad')"
      @get-list="debouncedReloadTable"
    ></ad-url>
    <!-- 创建类似受众 -->
    <el-dialog
      title="用广告系列、广告组的转化数据创建类似受众"
      :visible.sync="creatAudienceShow"
      width="1000px"
    >
      <div>
        <creat-audience
          :data_obj="{
            accountId: accountId,
            TargetingCountry: initDataAdCreat.targeting ? initDataAdCreat.targeting.TargetingCountry : [],
          }"
          :lists="selectedRowScopes.filter((v) => v.level !== 'ad')"
          @close="creatAudienceShow = false"
        ></creat-audience>
      </div>
    </el-dialog>
    <!-- 编辑主页 -->
    <edit-main-page
      :editPageShow.sync="editPageShow"
      :list="selectedRowScopes.filter((v) => v.level == 'ad')"
    ></edit-main-page>
    <!-- 定时开启 -->
    <set-time
      ref="setTimeVue"
      :adScheduleOpenTaskType="adScheduleOpenTaskType"
      :selectedRowScopes="selectedRowScopes"
      :setTimeIds="setTimeIds"
      :level="level"
      platform="facebook"
      @settingComplete="settingComplete"
      :type="setTimeType"
    />
    <!-- 操作日志 -->
    <logDraw
      :show.sync="showLog"
      needSign
      :apiParams="{
        platform: 'facebook',
        level: level,
        campaignId: operationData.campaign_id,
        adGroupId: operationData.adset_id,
        adId: level == 'ad' ? operationData.id : null,
      }"
    ></logDraw>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';
import AdCellInlineEditBudget from '@/views/adManagement/components/table/AdCellInlineEditBudget';
import AdCellName from '@/views/adManagement/components/table/AdCellName';
import batchEditPixel from '@/views/adManagement/components/table/batchEditPixel';
import adUrl from '@/views/adManagement/components/table/adUrl';
import DraftBox from '@/views/adManagement/components/DraftBox';
import cusColumnDialog from '@/views/adManagement/components/cusColumnDialog/index';
import chartDrawer from '@/views/adManagement/components/chartDrawer/index';
import EditAdGroup from '@/views/adManagement/createAd/components/editAdGroup.vue';
import editAds from '@/views/adManagement/createAd/components/editAd';
import editAdName from '@/views/adManagement/createAd/components/editAdName';
import editBudgetPrice from '@/views/adManagement/components/budget_price/editBudgetPrice.vue';
import downLoad from '@/views/adManagement/components/tableTopBar/downLoad.vue';
import { logDraw, adCellProductReport, campaignLabel } from '@adCommonComponent';
import { ProductReport } from '@adCommonComponent/utils/ProductReport.js';
import relatedGoods from './relatedGoods';
import batchEditAds from './batchEditAds';
import batchEdit from '../batchEdit/edit';
import editCopy from '../editCopy/index';
// import campaignLabel from './campaignLabel';
import pageName from './pageName.vue';
import { adConstsMap } from '@/api/creatAd';

import {
  breakdown,
  breakdownRules,
  deleteColumn,
  deleteFilter,
  myFilters,
  report,
  reportConsts,
  saveFilter,
  myColumns,
  mutateStatus,
  alertScore,
  AIUpload,
  queryConfigLabel,
} from '@/api/adManagement';
import CopyDrawer from '@/views/adManagement/components/copy/CopyDrawer';
import bidAmount from '@/views/adManagement/components/table/bidAmount';
import localOrder from '@/views/adManagement/components/table/localOrder';
import editMainPage from '@/views/adManagement/components/table/editMainPage';
// 创建受众
import creatAudience from '@/views/adManagement/components/table/creatAudience/index.vue';
// 定时开启
import setTime from './setTime.vue';
// 今日订单
import todayOrder from '@/views/adManagement/components/table/todayOrder';
// 状态
import status from './status.vue';
// 组件混入
import { delAdScheduleUpdateBudgetTask, delAdScheduleOpenTask, delUpdateBidTask } from '@/api/adManagement.js';

//筛选【封装】，细分，头部操作，自定义列，弹框，放表格里
///日期选择，放index
const statusList = [
  { key: 'ACTIVE', campaign_val: 1, adset_val: 1, ad_val: 1 },
  { key: 'PAUSED', campaign_val: 7, adset_val: 9, ad_val: 8 },
  { key: 'DELETED', campaign_val: 6, adset_val: 8, ad_val: 7 },
  { key: 'PENDING_REVIEW', campaign_val: 3, adset_val: 3, ad_val: 3 },
  { key: 'DISAPPROVED', campaign_val: 2, adset_val: 2, ad_val: 2 },
  { key: 'PREAPPROVED', campaign_val: 8, adset_val: 10, ad_val: 9 },
  { key: 'PENDING_BILLING_INFO', campaign_val: 9, adset_val: 11, ad_val: 10 },
  { key: 'CAMPAIGN_PAUSED', campaign_val: 10, adset_val: 7, ad_val: 11 },
  { key: 'ARCHIVED', campaign_val: 11, adset_val: 12, ad_val: 12 },
  { key: 'ADSET_PAUSED', campaign_val: 12, adset_val: 13, ad_val: 16 },
  { key: 'IN_PROCESS', campaign_val: 13, adset_val: 4, ad_val: 4 },
  { key: 'WITH_ISSUES', campaign_val: 14, adset_val: 14, ad_val: 5 },
  { key: 'NOAD', campaign_val: 4, adset_val: 6, ad_val: 13 },
  { key: 'CLOSED', campaign_val: 5, adset_val: 4, ad_val: 14 },
];
function getIndex(key, level) {
  return statusList.filter((v) => v.key == key).length
    ? statusList.filter((v) => v.key == key)[0][`${level}_val`]
    : 100;
}

const AiSuggestionTypes = {
  PAUSE: {
    icon: 'icon-guanting-copy-copy',
    class: 'cF88',
  },
  SUB_BUDGET: {
    icon: 'icon-zengjiayusuan-copy1',
    class: 'cFB6',
  },
  ADD_BUDGET: {
    icon: 'icon-zengjiayusuan',
    class: 'c80D580',
  },
  NO_OP: {
    icon: 'icon-xianzhuang',
    class: 'c99bbff',
  },
};
export default {
  name: 'AdTable',
  props: {
    dateRange: {
      type: Object,
      default: () => {},
    },
    level: {
      type: String,
      default: 'campaign',
    },
    activeLevel: {
      type: String,
      default: 'campaign',
    },
    accountId: {
      type: String,
      default: '',
    },
    initData: {
      type: Object,
      default: () => {},
    },
    adsCountObj: {
      type: Object,
      default: () => {},
    },
    selectIds: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      listLoading: false, // 正在请求列表接口
      AiSuggestionTypes,
      assessLoading: false,
      adType: '创建标准广告',
      filterIcon: require('@/assets/icon/filter.png'),
      keyword: '',
      tableData: [],
      disAbled: false,
      // loading: false,
      fields: [],
      params: {
        account_id: this.accountId,
        action_attribution_windows: this.dateRange.actionAttributionWindows || [],
        after: '',
        date_end: this.dateRange.dateRange[1] || '2020-10-01',
        date_start: this.dateRange.dateRange[0] || '2020-02-20',
        fields: [],
        filtering: [],
        object_filtering: [],
        keyword: '',
        level: 'campaign',
        page_length: 50,
        sort: [],
      },
      after: '',
      expandAll: false, //是否展开所有下级，当加载细分的时候默认展开
      expandKeys: [],
      scrollDisable: false, //表格无限滚动是否禁用
      loadText: '滚动/点击加载更多', //默认加载文字
      breakdownRulesArr: [],
      breakdowms: {
        time: '',
        delivery: '',
      },
      breakdownGroup: [],
      maps: new Map(),
      infiniteScrollDisabled: false,
      lazy: true,
      // 搜索框
      searchInput: false,
      // 自定义列
      initCheckedColumns: [],
      cusColumnVisible: false,
      myColumnsArr: [],
      myColumsName: '',
      myColumsId: '',
      myCols: {
        myColumnsArr: [],
        myColumsName: '',
        myColumsId: '',
      },
      // 筛选条件
      filterList: [], //我的自定义筛选条件
      cusFilterList: [{ field: '', operator: '', value: '' }],
      filterId: 0,
      filterShow: false,
      checkList: [],
      filterName: '',
      saveFilterShow: false,
      drawers: {
        chart: { visible: false, rowScopes: [] }, // 图表抽屉
        copy: { visible: false, rowScopes: [] }, // 复制抽屉
        editBudgetPrice: { visible: false, rowScopes: [], level: this.level }, // 修改竞价预算
      },
      selectedRowScopes: [],
      // 修改广告广告组
      initDataAdCreat: {},
      editGroupShow: false,
      editAdsShow: false,
      editAdSetId: 0,
      editAdId: 0,
      // 取消上一次请求
      source: null,
      downLoadParams: null,
      relateSale: false, //关联商品
      rowMsg: null,
      // 批量编辑广告创意
      batchEditAds: false,
      adScope: null,
      adsetScope: null,
      creatAdsShow: false,
      creatAdParentId: null,
      editNameShow: false,
      editAdsetNameShow: false,
      editPixelShow: false,
      batchEditShow: false,
      editCopyShow: false,
      editUrlShow: false,
      summary: {}, //合计行
      // 经营分析
      manageAssess: [],
      list: [],
      manualSortFlag: null,
      creatAudienceShow: false,
      editPageShow: false,
      setTimeIds: [], // 定时开启id集合
      adScheduleOpenTaskType: [], // 定时开启 时间
      hasAdActive: 'false', //是否有投放中的广告
      radioValue: '1',
      // labelList:[],//筛选条件广告系列标签
      addAdLabelShow: false,
      showLog: false, // 显示操作日志
      operationData: {}, // 操作的数据
      loadFilter: false, //是否加载过我的筛选条件
      //   open_schedule: [], //筛选是否有定时
      setTimeType: 1, //定时类型
      loadAccountWatch: false,
    };
  },
  components: {
    adCellProductReport,
    CopyDrawer,
    AdCellName,
    AdCellInlineEditBudget,
    DraftBox,
    cusColumnDialog,
    chartDrawer,
    EditAdGroup,
    editAds,
    editBudgetPrice,
    downLoad,
    relatedGoods,
    batchEditAds,
    editAdName,
    batchEditPixel,
    batchEdit,
    editCopy,
    adUrl,
    localOrder,
    creatAudience,
    editMainPage,
    setTime,
    campaignLabel,
    bidAmount,
    pageName,
    todayOrder,
    status,
    logDraw,
  },
  filters: {
    showName(item) {
      return item ? (item['name'] ? item['name'] : '') : '请选择';
    },
  },
  computed: {
    ...mapState('user', ['userInfo']),
    ...mapState('num', ['selectedNum']),
    ...mapGetters('num', ['toFormatedMoneyV2', 'currentUiUnit']),
    ...mapGetters('adActiveTab', [
      'selectCampaignsOld',
      'selectCampaignsNew',
      'selectAdsetsOld',
      'selectAdsetsNew',
      'campaignDateRangOld',
      'campaignDateRangNew',
      'adsetDateRangNew',
      'adsetDateRangOld',
      'adDateRangNew',
      'adDateRangOld',
      'labelList',
    ]),
    ...mapState('initData', ['campaignMsg']),
    editCopyList() {
      return this.list.filter((v) => v.level == this.level);
    },
    // checkList(){
    //     let list = this.params.filtering.filter(v=>v.filed == 'status');
    //     if(list.length){
    //         return list[0].value1;
    //     }else{
    //         this.params.filtering.push({filed:'status',operator:'IN',value1:[],value:''})
    //         return this.params.filtering.filter(v=>v.filed == 'status')[0].value1;
    //     }
    // },
    openSchedule() {
      //   return this.initData.FilterFields.find((v) => v.keyName == 'open_schedule')?.enumValues || [];
      return [{ key: true, desc: '有定时' }];
    },
    open_schedule: {
      get() {
        return this.params.filtering.find((v) => v.field == 'open_schedule')?.value1 || [];
      },
      set(v) {
        this.selectSchedule(v);
      },
    },
  },
  watch: {
    accountId: function (v, ov) {
      this.removeQueryParam('campaign_id');
      this.debouncedReloadTable();
    },
    dateRange: {
      handler(val) {
        this.setDateRangeParam();
        // this.breakdownRules();
      },
      deep: true,
    },
    campaignMsg: function () {
      this.loadAccountWatch = true;
      if (this.campaignMsg?.loadTable) {
        //   获取切换账户选择的系列id
        let campaignId = this.campaignMsg.campaignId;
        console.log(this.params.level);
        if (this.params.level == 'campaign') {
          this.params.keyword = campaignId ? campaignId : this.params.keyword;
          this.searchInput = !!this.params.keyword;
          //   this.params.keyword = this.params.keyword;
        }
        if (!this.campaignMsg.changeAccount) {
          this.debouncedReloadTable();
        }
      }
    },
  },
  async mounted() {
    this.downLoadParams = JSON.parse(JSON.stringify(this.params));
    await this.myColumns();
    // this.getMyFilter();
    this.breakdownRules();
    // await this.getInitData();
    this.debouncedReloadTable();
    // 获取广告创建常量(后续优化放到编辑广告组，编辑广告里面)
    this.getInitDataAdCreat();
    await this.getLabelList();
  },
  // 注册自定指令
  directives: {
    'infinite-scroll': {
      bind(el, binding) {
        const selectWrap = el.querySelector('.el-table__body-wrapper');
        selectWrap.addEventListener('scroll', function () {
          if (selectWrap.scrollHeight == selectWrap.clientHeight) return;
          let sign = binding.value.distance;
          const scrollDistance = this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value.load();
          }
        });
      },
    },
  },
  updated() {
    this.$refs['table'].doLayout();
  },
  methods: {
    ...mapMutations(['audiences/setMultipleAdsets']),
    // 取消定时
    cancelSet(type, row, scopeindex, index, id) {
      let _item = JSON.parse(JSON.stringify(row));
      let msg = '';
      let request = null;
      if (type === 'task') {
        msg = '确定取消定时开启 | 关闭任务吗？';
        request = delAdScheduleOpenTask;
      } else if (type == 'bid') {
        msg = '确定取消定时修改出价吗？';
        request = delUpdateBidTask;
      } else {
        msg = '确定取消定时修改预算吗？';
        request = delAdScheduleUpdateBudgetTask;
      }
      this.$confirm(msg, '提示', {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          let data = {
            id: type == 'bid' ? id : row.id,
            taskId: id,
          };
          request(JSON.stringify(data)).then((res) => {
            if (res.code == 0) {
              this.$message.success('取消成功！');
              _item.open_schedule.splice(index, 1);
              this.$set(this.tableData, scopeindex, _item);
            }
          });
        })
        .catch(() => {});
    },
    labelChange() {
      this.debouncedReloadTable();
    },
    // 定时开启
    setTimeHandle(type) {
      console.log(type);
      this.setTimeType = type;
      //   if (type == 1) {
      //     this.setTimeIds = this.selectedRowScopes.filter((item) => item.status === 'PAUSED').map((v) => v.id);
      //   }
      //   if (type == 4) {
      //     this.setTimeIds = this.selectedRowScopes.filter((item) => item.status === 'ACTIVE').map((v) => v.id);
      //   }
      this.setTimeIds = this.selectedRowScopes.map((v) => v.id);
      // this.selectedRowScopes.forEach((item) => {
      //   if (item.status === 'PAUSED') {
      //     this.setTimeIds.push(item.id);
      //   }
      // });
      this.$refs.setTimeVue.show();
      //   if (this.setTimeIds.length == this.selectedRowScopes.length) {
      //     this.$refs.setTimeVue.show();
      //   } else {
      //     this.$message.warning(`包含${type == 1 ? '投放中' : '已关闭'}的系列 | 组`);
      //   }
    },
    // AI助手更新推荐
    updateAI() {
      const campaign_ids = this.selectedRowScopes.map((item) => item.campaign_id);
      this.$showLoading();
      AIUpload({ campaign_ids })
        .then((res) => {
          if (res.data) {
            res.data.forEach((item) => {
              let rowIndex = this.selectedRowScopes.findIndex((row) => item.obj_id == row.campaign_id);
              if (rowIndex > -1) {
                this.$set(this.selectedRowScopes[rowIndex], 'ai_suggestion', { ...item });
              }
            });
          }
        })
        .finally(() => {
          this.$hideLoading();
        });
    },
    // 设置定时时间
    settingComplete(form, type, id, budgetAmount, taskId) {
      console.log(form, type, id, budgetAmount, taskId);
      let setTimeIds = [];
      if (type == 2 || type == 3) {
        setTimeIds.push(id);
      } else {
        setTimeIds = this.setTimeIds;
      }

      console.log(setTimeIds);
      this.tableData.forEach((item, index) => {
        if (setTimeIds.indexOf(item.id) > -1) {
          let openSchedule = item.open_schedule || [];
          if (openSchedule.length > 0) {
            openSchedule.forEach((_item, _index) => {
              if (_item.type == type) {
                openSchedule.splice(_index, 1);
              }
            });
          }
          openSchedule.push({
            type: type,
            timeStr: form.dateVal, //+ this.adScheduleOpenTaskType.filter((v) => v.name === form.taskType)[0].desc,
            budget: budgetAmount ? budgetAmount : null,
            bidAmount: budgetAmount ? budgetAmount : null,
            id: taskId,
          });
          item.open_schedule = openSchedule;
          this.$set(this.tableData, index, item);
        }
      });
    },
    // 创建类似受众
    creatAudiecnce() {
      this.creatAudienceShow = true;
    },
    alertScore({ id, score }) {
      let params = {
        id,
        score,
      };
      alertScore(params).then((res) => {
        if (res.code == 0) {
        }
      });
    },
    reviewScore({ curTableRow, curTableIndex, curRow, curIndex, flag }) {
      if (curTableRow.level == this.level) {
        this.$refs.table.store.states.data.map((k, i) => {
          if (k.id == curTableRow.id) {
            this.$refs.table.store.states.data[i].status_hints[curIndex].alertScore = flag;
          }
        });
      } else {
        // 不是当前级别
        let parentId = curTableRow.level == 'adset' ? curTableRow.campaign_id : curTableRow.adset_id;
        let list = this.$refs.table.store.states.lazyTreeNodeMap[parentId];
        list.map((v, index) => {
          if (v.id == curTableRow.id) {
            this.$refs.table.store.states.lazyTreeNodeMap[parentId][index].status_hints[curIndex].alertScore = flag;
          }
        });
      }
      this.alertScore({ id: curRow.alertId, score: flag });
    },
    tabChangeReloadTable(level) {
      // console.log(this.activeLevel);
      if (level == 'campaign') {
        if (JSON.stringify(this.campaignDateRangNew) !== JSON.stringify(this.campaignDateRangOld)) {
          this.$store.commit('adActiveTab/setCampaignDateRangOld', this.dateRange);
          this.debouncedReloadTable();
          this.setSel();
        }
      }
      if (level == 'adset') {
        if (
          JSON.stringify(this.selectCampaignsOld) !== JSON.stringify(this.selectCampaignsNew) ||
          JSON.stringify(this.adsetDateRangNew) !== JSON.stringify(this.adsetDateRangOld)
        ) {
          this.$store.commit('adActiveTab/setSelectCampaignOld', this.selectCampaignsNew);
          this.$store.commit('adActiveTab/setAdsetDateRangOld', this.dateRange);
          this.debouncedReloadTable();
          this.setSel();
        }
      }
      if (level == 'ad') {
        if (
          JSON.stringify(this.selectAdsetsOld.campaign) !== JSON.stringify(this.selectCampaignsNew) ||
          JSON.stringify(this.selectAdsetsOld.adset) !== JSON.stringify(this.selectAdsetsNew) ||
          JSON.stringify(this.adDateRangNew) !== JSON.stringify(this.adDateRangOld)
        ) {
          this.$store.commit('adActiveTab/setSelectAdsetsOld', {
            campaign: this.selectCampaignsNew,
            adset: this.selectAdsetsNew,
          });
          this.$store.commit('adActiveTab/setAdDateRangOld', this.dateRange);
          this.debouncedReloadTable();
        }
      }
    },
    rowClick(row, column, event) {
      // console.log('rowClick(row, column, event)',row, column, event)
      if (column && column.label && column.label != '预算') {
        this.$refs.table.toggleRowSelection(row);
        //  console.log('行点击了-我不是预算')
      }
      // console.log('行点击了')
    },
    // 选中状态回显
    setSel() {
      let sel = [];
      // if(this.level=='campaign'){
      //     sel=this.selectIds.selectCampaigns
      // }else
      if (this.level == 'adset') {
        sel = this.selectIds.selectAdsets;
      }

      sel.forEach((item) => {
        let obj = this.tableData.find((row) => row.id == item.id);
        if (obj) {
          this.$refs.table.toggleRowSelection(obj, true);
        }
      });
    },
    getAdsetReport(adsetInfo, isChild, campaginInfo) {
      if (!isChild) {
        const sel = this.selectIds?.selectCampaigns || [];
        campaginInfo = sel.find((s) => s.campaign_id == adsetInfo.campaign_id);
      }
      const adProductReport = campaginInfo?.adProductReport;
      if (campaginInfo && adProductReport && adsetInfo.local_order?.validOrderCount) {
        let purchase = adsetInfo.local_order.validOrderCount;
        let moneyRate = adProductReport.adCost / campaginInfo.spend;
        let spend = adsetInfo.spend * moneyRate;
        return new ProductReport(adProductReport, purchase, spend);
      }
      return null;
    },
    cellStyle({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1) {
        return 'height:60px!important;';
      }
    },
    headerCellClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 2) {
        return 'header-status';
      }
    },
    // 关联商品
    relateSaleClick(row) {
      this.rowMsg = row;
      this.relateSale = true;
    },
    cancelQuest() {
      if (typeof this.source === 'function') {
        // console.log('this.source',this.source)
        this.source(); //取消请求
      }
    },
    specialFields(params) {
      if (params.level === 'ad') {
        return ['creative_thumbnail'];
      }
      if (params.fields.indexOf('product_report') >= 0) {
        if (params.fields.indexOf('local_order') >= 0) {
          if (params.fields.indexOf('spend') > 0) {
            return ['adProductReport'];
          } else {
            return ['adProductReport', 'spend'];
          }
        } else {
          return ['adProductReport', 'local_order'];
        }
      }
      return [];
    },
    // 获取广告创建常量(编辑的时候会用到)
    getInitDataAdCreat() {
      adConstsMap().then((res) => {
        // console.log(res);
        this.initDataAdCreat = res.data;
        this.adScheduleOpenTaskType = this.initDataAdCreat?.ad?.AdScheduleOpenTaskType;
        this.$store.commit('initData/setInitData', res.data);
      });
    },
    close(v) {
      this[v] = false;
    },
    openChart(v) {
      this.drawers.chart.visible = true;
      this.drawers.chart.currentRow = v;
    },
    setDateRangeParam() {
      // console.log('dateRange',this.dateRange);
      this.params.action_attribution_windows = this.dateRange.actionAttributionWindows;
      this.params.date_start = this.dateRange.dateRange[0];
      this.params.date_end = this.dateRange.dateRange[1];
      if (this.level == 'campaign') {
        this.$store.commit('adActiveTab/setCampaignDateRangNew', this.dateRange);
      }
      if (this.level == 'adset') {
        this.$store.commit('adActiveTab/setAdsetDateRangNew', this.dateRange);
      }
      if (this.level == 'ad') {
        this.$store.commit('adActiveTab/setAdDateRangNew', this.dateRange);
      }
      this.debouncedReloadTable();
      if (this.level == 'campaign') {
        this.$store.commit('adActiveTab/setCampaignDateRangOld', this.dateRange);
      }
      if (this.level == 'adset') {
        this.$store.commit('adActiveTab/setAdsetDateRangOld', this.dateRange);
      }
      if (this.level == 'ad') {
        this.$store.commit('adActiveTab/setAdDateRangOld', this.dateRange);
      }
    },
    formatedCellValue(row, columnDefine) {
      let keyName = columnDefine.keyName;
      let type = columnDefine.type;
      let v = row[keyName];
      let empty = false;
      if (v === undefined || v === null || v == 0) empty = true;
      switch (type) {
        case 'STRING':
          if (empty) return '-';
          return v;
        case 'MONEY':
          if (empty) {
            // if(keyName=='cost_per_action_type'){
            //     return `<p style="font-size:15px;color:#606770;">-</p><p class="color9">购买</p>`;
            // }else{
            return '-';
            // }
          } else {
            return this.actionValueDesc(keyName, this.formatedMoney(v, keyName !== 'bid_amount'), row);
          }
        case 'PERCENTAGE':
          if (empty) return '-';
          return parseFloat(v).toFixed(2) + '%';
        case 'INT':
          if (empty) {
            // if(keyName=='object_actions'){
            //     return `<p style="font-size:15px;color:#606770;">-</p><p class="color9">购买</p>`;
            // }else{
            return '-';
            // }
          } else {
            return this.actionValueDesc(keyName, parseInt(v), row);
          }
        case 'DECIMAL':
          if (empty) return '-';
          return parseFloat(v).toFixed(2);
        default:
          return v;
      }
    },
    formatedCellValueV2(row, columnDefine) {
      let keyName = columnDefine.keyName,
        type = columnDefine.type,
        v = row[keyName],
        empty = false;
      if (v === undefined || v === null || v == 0) empty = true;
      let val = '--',
        desc = '',
        obj = {};
      if (!empty) {
        switch (type) {
          case 'MONEY':
            obj = this.actionValueDescV2(keyName, this.formatedMoney(v, keyName !== 'bid_amount'), row);
            val = obj.val;
            desc = obj.desc;
            break;
          case 'PERCENTAGE':
            val = parseFloat(v).toFixed(2) + '%';
            break;
          case 'INT':
            obj = this.actionValueDescV2(keyName, parseInt(v), row);
            val = obj.val;
            desc = obj.desc;
            break;
          case 'DECIMAL':
            val = parseFloat(v).toFixed(2);
            break;
          default:
            val = v;
            break;
        }
      }
      let str = `<p style="font-weight:700;">${
        val || '--'
      }</p><p class="color9" style="height:20px;line-height:20px;">${desc}</p>`;
      return str;
    },
    actionValueDesc(field, value, row) {
      switch (field) {
        case 'cost_per_action_type':
          if (row[field + '_desc']) {
            // return value+'/'+row[field+'_desc']
            return `${value || '-'}${row[field + '_desc']}`;
          }
        case 'object_actions':
          if (row[field + '_desc']) {
            // return value+row[field+'_desc']
            return `${value || '-'}${row[field + '_desc']}`;
          }
      }
      return value;
    },
    actionValueDescV2(field, value, row) {
      if (field == 'cost_per_action_type' || field == 'object_actions') {
        if (row[field + '_desc']) {
          return {
            val: value || '--',
            desc: row[field + '_desc'],
          };
        }
      } else {
        return {
          val: value,
          desc: '',
        };
      }
    },
    formatedMoney(v, base) {
      return this.$store.getters.toFormatedMoney(v, base).formated;
      // return "$" + (parseFloat(v) / 100).toFixed(2);
    },
    // 批量操作
    //
    // 点击编辑
    handleEditCommand(v) {
      // console.log(v);
      // 编辑广告系列竞价预算
      if (v.type == 'campaign' || v.type == 'adset') {
        this.drawers.editBudgetPrice.visible = true;
        if (v.scope) {
          // 单个
          this.drawers.editBudgetPrice.rowScopes = [v.scope.row];
          this.drawers.editBudgetPrice.level = v.scope.row.level;
        } else {
          // 批量
          this.drawers.editBudgetPrice.rowScopes = this.selectedRowScopes;
          this.drawers.editBudgetPrice.level = this.level;
        }
      }
      // 编辑广告组
      if (v.type == 'editAdset') {
        this.editGroupShow = true;
        this.editAdSetId = v.scope.row.id;
        this.adsetScope = v.scope;
      }
      // 编辑广告
      if (v.type == 'editAd') {
        this.editAdsShow = true;
        this.editAdId = v.scope.row.id;
        this.adScope = v.scope;
      }
      // 创建广告
      if (v.type == 'creatAd') {
        this.creatAdsShow = true;
        this.creatAdParentId = v.scope.row.id;
      }
      // 批量修改广告创意
      if (v.type == 'ad') {
        this.batchEditAds = true;
      }
      // 批量修改广告名称
      if (v.type == 'adName') {
        this.editNameShow = true;
      }
      // 批量修改广告网域
      if (v.type == 'adUrl') {
        this.editUrlShow = true;
      }
      // 批量修改像素
      if (v.type == 'adsetPix') {
        this.editPixelShow = true;
      }
      // 批量修改广告组
      if (v.type == 'adsets') {
        this.batchEditShow = true;
      }
      // 批量修改广告组名称
      if (v.type == 'adsetName') {
        this.editAdsetNameShow = true;
      }
      // 编辑广告
      if (v.type == 'editAds') {
        this.batchEditShow = true;
      }
      // 批量编辑主页
      if (v.type == 'editPages') {
        this.editPageShow = true;
      }
    },
    // 点击复制
    handleCopyCommand(v) {
      // console.log(v);
      this.showDrawer('copy', this.selectedRowScopes, v);
    },
    // field
    operatorName(v, tag) {
      return v
        ? v.filtering.filter((m) => m.key == tag.operator)[0]
          ? v.filtering.filter((m) => m.key == tag.operator)[0].desc
          : ''
        : '请选择';
    },
    // 显示筛选条件符号下拉
    showFiltering(tag) {
      // console.log(tag);
      let list = this.initData.FilterFields.filter((v) => v.keyName == tag.field);
      // console.log(list);
      if (list.length) {
        return list[0].filtering;
      } else {
        return [];
      }
    },
    // 显示筛选条件的值
    valueName(tag) {
      let list = [];
      tag.value1 = tag.value1 || tag.value;
      tag.value1.map((item) => {
        // console.log(item);
        if (tag.field == 'objective') {
          this.initData.CampaignObjective.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        } else if (tag.field == 'status') {
          this.initData.FilteringStatus.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        } else if (tag.field == 'open_schedule') {
          this.openSchedule.map((v) => {
            if (item == v.key) {
              list.push(v.desc);
            }
          });
        } else {
          this.labelList.map((v) => {
            if (item == v.labelId) {
              list.push(v.name);
            }
          });
        }
      });
      return list ? list : '请选择';
    },
    // checkList

    // 获取常量数据
    async getInitData() {
      await reportConsts().then((res) => {
        this.initData = res.data;
      });
    },
    // 获取我之前定制的列组合
    async myColumns() {
      const params = { accountId: this.$store.getters.accountId };
      await myColumns(params).then((res) => {
        if (res.code == 0) {
          this.myCols.myColumnsArr = res.data;
          let myColumsId = localStorage.getItem('myColumsId_' + this.level);
          if (myColumsId) {
            this.fields = JSON.parse(localStorage.getItem('myColums_' + this.level));
          } else {
            this.fields = res.data[0].columns;
          }
          this.myCols.myColumsId = myColumsId;
        }
      });
    },
    showDrawer(drawerName, rowScopes, level) {
      // console.log('drawerName, rowScopes, level',drawerName, rowScopes, level)
      for (let k in this.drawers) {
        this.drawers[k].visible = k === drawerName;
        this.drawers[k].rowScopes = JSON.parse(JSON.stringify(rowScopes.filter((v) => v.level == level)));
        this.drawers[k].level = level;
      }
    },
    // 点击筛选
    openFilter(v) {
      if (v && !this.loadFilter) {
        console.log('aaaaaaaaaaaaaaa');
        this.getMyFilter();
      }
    },
    // 切换筛选条件
    handleFilterCommand(v) {
      this.filterShow = true;
      let filtering = JSON.parse(JSON.stringify(v.filtering));
      this.params.filtering = filtering.map((v) => {
        if (v.field == 'objective' || v.field == 'status' || v.field == 'ad_label' || v.field == 'open_schedule') {
          v.value1 = v.value;
        }
        return v;
      });
      this.saveFilterStorage();
      // this.filterShow = true;
      // console.log(this.filterShow);
      this.debouncedReloadTable();
    },
    // 监听筛选条件value的变换
    valueChange(v) {
      // console.log(v);
      this.saveFilterStorage();
      this.debouncedReloadTable();
    },
    // 筛选条件符号的变化
    operatorChange() {
      this.saveFilterStorage();
    },
    // 筛选条件存入缓存
    saveFilterStorage() {
      localStorage.setItem(`${this.level}_filter`, JSON.stringify(this.params.filtering));
    },
    objectFilteringBySection() {
      let filteringCampaignIds = [];
      let filteringAdseIds = [];
      if (this.selectIds && this.selectIds.selectCampaigns && this.selectIds.selectCampaigns.length > 0) {
        let obj = {
          field: 'campaign.id',
          operator: 'IN',
          value: this.selectIds.selectCampaigns.map((v) => v.id),
        };
        filteringCampaignIds.push(obj);
      }
      if (this.selectIds && this.selectIds.selectAdsets && this.selectIds.selectAdsets.length > 0) {
        let obj = {
          field: 'adset.id',
          operator: 'IN',
          value: this.selectIds.selectAdsets.map((v) => v.id),
        };
        filteringAdseIds.push(obj);
      }
      switch (this.level) {
        case 'adset':
          return filteringCampaignIds;
        case 'ad':
          return filteringCampaignIds.concat(filteringAdseIds);
      }
      return [];
    },
    reloadTable() {
      //初始化，或刷新
      // console.log('开始-reload')
      document.getElementsByClassName('el-table__body-wrapper')[0].scrollTop = 0;
      this.after = '';
      // this.setDateRangeParam();
      // this.infiniteScrollDisabled = true;
      let params = JSON.parse(JSON.stringify(this.params));
      // this.$showLoading();
      // this.loading = true;
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = [
        'name',
        'status',
        'id',
        'campaign_ref',
        'campaign_id',
        'adset_id',
        'purchase',
        'bid_strategy',
        'roas_average_floor',
        'objective',
        'optimization_goal',
      ].concat(filedList);
      params.account_id = this.$store.getters.accountId;
      // console.log(params.level == this.level);
      params.after = '';
      // 处理筛选条件
      console.log(localStorage.getItem(`${this.level}_filter`));
      // console.log(JSON.parse(JSON.stringify(params.filtering)));

      let filtering = params.filtering;
      this.filterShow = !!filtering.length;
      // console.log(filtering);
      filtering.map((item) => {
        if (
          item.field == 'status' ||
          item.field == 'objective' ||
          item.field == 'ad_label' ||
          item.field == 'open_schedule'
        ) {
          item.value = item.value1 && item.value1.length ? JSON.parse(JSON.stringify(item.value1)) : item.value;
        }
        delete item.value1;
      });
      // 从filtering 里面取出object_filtering 的项
      let object_filtering = filtering.filter(
        (v) =>
          v.field == 'status' ||
          (v.field == 'objective' && v.value.length) ||
          (v.field == 'ad_label' && v.value.length) ||
          v.field == 'open_schedule',
      );
      params.object_filtering = object_filtering.concat(this.objectFilteringBySection());
      params.filtering = filtering.filter(
        (v) => v.field != 'status' && v.field != 'objective' && v.field != 'ad_label' && v.field != 'open_schedule',
      );
      // console.log('params',params);
      params.level = this.level;
      // console.log(this.activeLevel,this.level);
      if (this.level !== this.activeLevel) {
        return false;
      }
      // console.log(params);
      this.downLoadParams = JSON.parse(JSON.stringify(params));
      let campaignId = this.campaignMsg?.campaignId;
      if (params.level == 'campaign') {
        params.keyword = campaignId ? campaignId : this.params.keyword;
        this.searchInput = !!params.keyword;
        this.params.keyword = params.keyword;
      }
      console.log('params', params);
      return this.getList(params).then(async (res) => {
        this.$store.commit('initData/setCampaignMsg', {
          loadTable: false,
          campaignId: '',
        });
        this.tableData = res;
        if (!params.sort.length) {
          this.tableData.sort(this.compare('statusIndex', 'ascending'));
        }
        // this.$hideLoading();
        //  console.log("this.tableData.length,this.summary.total_count",this.tableData.length,this.summary.total_count)
        if (res.length < this.params.page_length) {
          this.loadText = '没有更多数据了';
          this.infiniteScrollDisabled = true;
        } else {
          this.loadText = '滚动/点击加载更多';
          this.infiniteScrollDisabled = false;
        }

        // console.log(res);
        // 清除掉子项缓存
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          delete this.$refs.table.store.states.lazyTreeNodeMap[v];
        });
        this.tableData.map((v) => {
          if (this.$refs.table.store.states.treeData[v]) {
            this.$refs.table.store.states.treeData[v].loaded = false;
            this.$refs.table.store.states.treeData[v].expanded = false;
            this.$refs.table.store.states.treeData[v].children = [];
            this.$set(this.$refs.table.store.states.treeData, v, this.$refs.table.store.states.treeData[v]);
          }
        });
        this.clearChidren(this.tableData.map((v) => v.id));
        this.$forceUpdate();
        return;
      });
    },
    // 和几行数据
    getSummaries(param) {
      const { columns, data } = param;
      // console.log(data);
      const sums = [];
      columns.map((item, index) => {
        if (index == 0) {
          sums[index] = '';
          return;
        } else if (index == 1) {
          // let n = this.adsCountObj[this.level + 's'];
          let n = this.summary?.total_count;
          // if (
          //   (this.level == "adset" && this.selectIds.selectCampaigns.length) ||
          //   (this.level == "ad" && this.selectIds.selectAdsets.length)
          // ) {
          //   n = this.tableData.length;
          // }
          sums[index] = `${n}条${
            this.level == 'campaign' ? '广告系列' : this.level == 'adset' ? '广告组' : '广告'
          }的成效`;
        } else {
          let row = this.fields.filter((v) => v.keyName == item.property)[0];
          if (row) {
            sums[index] = this.summary ? this.formatedCellValue(this.summary, row) : '--';
          } else {
            sums[index] = '--';
          }
        }
      });
      return sums;
    },
    // 删除当前筛选条件
    deleFilterTag(tag, i) {
      this.params.filtering.splice(i, 1);
      if (!this.params.filtering.length) {
        this.filterShow = false;
      }
      if (tag.field == 'status') {
        this.checkList = [];
      }
      tag.value1 = tag.value1 ? tag.value1 : [];
      if (tag.field && tag.operator && (tag.value !== '' || tag.value1.length)) {
        this.saveFilterStorage();
        this.debouncedReloadTable();
      }
    },
    // 监听筛选条件field的变化
    fieldChange(list, v, i) {
      let obj = JSON.parse(JSON.stringify(v));
      if (obj.field == 'status' || obj.field == 'objective' || obj.field == 'ad_label') {
        obj.operator = 'IN';
      } else if (obj.field == 'account_name' || obj.field == 'campaign_name' || obj.field == 'adset_name') {
        obj.operator = 'CONTAIN';
      } else if (obj.field == 'open_schedule') {
        obj.operator = 'EQUAL';
      } else {
        obj.operator = '';
      }
      obj.value = '';
      obj.value1 = [];
      this.$set(list, i, obj);
    },
    // 删除筛选条件
    deleFilterObj(i) {
      this.cusFilterList.splice(i, 1);
    },
    // 控制筛选条件下拉不被隐藏
    showFilterDrop() {
      this.$nextTick((res) => {
        this.$refs.filterDrop.dropdown.show();
      });
    },
    // 添加筛选条件
    addFilter(v) {
      let cusFilterList = this.cusFilterList;
      let flag = true;
      cusFilterList.map((item) => {
        if (!item.field || !item.operator || (!item.value && !item.value1.length)) {
          this.$message({
            type: 'warning',
            message: '请填写完整的信息',
          });
          flag = false;
        } else {
        }
      });
      if (flag) {
        // if(v){
        this.cusFilterList.push({
          field: '',
          operator: '',
          value: '',
          value1: [],
        });
        // }
      }
      return flag;
    },
    // 添加当前筛选条件
    addTag() {
      let obj = { field: '', operator: '', value: '', value1: [] };
      this.params.filtering.push(obj);
    },
    // 保存为我的筛选条件
    saveMyFilter() {
      let data = {
        name: this.filterName,
      };
      if (!this.filterName) {
        this.$message({
          type: 'warning',
          message: '请输入筛选条件名称',
        });
        return false;
      }
      let filters = this.params.filtering;
      if (!filters.length) {
        this.$message({
          type: 'warning',
          message: '请添加筛选条件再保存',
        });
        return false;
      }
      data.filtering = filters;
      console.log(filters);
      this.$showLoading();
      data.filtering.map((v) => {
        v.value = v.value1 && v.value1.length ? v.value1 : v.value;
      });
      // this.loading=true
      saveFilter(JSON.stringify(data)).then((res) => {
        // console.log(res);
        this.$hideLoading();
        // this.loading=false
        if (res.code == 0) {
          this.saveFilterShow = false;
          this.filterName = '';
          this.getMyFilter();
        }
      });
    },
    // 关闭当前的筛选条件
    closeMyFilter() {
      this.filterShow = false;
      this.cusFilterList = [{ field: '', operator: '', value: '', value1: [] }];
      this.params.filtering = [];
      this.checkList = [];
      this.open_schedule = [];
      this.saveFilterStorage();
      this.debouncedReloadTable();
    },
    // 保存为当前的筛选条件
    saveFilterSearch() {
      if (this.addFilter()) {
        // console.log(this.cusFilterList);
        this.filterShow = true;
        this.params.filtering = JSON.parse(JSON.stringify(this.cusFilterList)).slice(0, this.cusFilterList.length - 1);
        if (this.params.filtering.filter((v) => v.field == 'status').length) {
          this.checkList = this.params.filtering.filter((v) => v.field == 'status')[0].value1;
        }
        this.cusFilterList = JSON.parse(JSON.stringify(this.cusFilterList)).slice(0, this.cusFilterList.length - 1);
        this.saveFilterStorage();
        this.debouncedReloadTable();
      }
    },
    // 删除我的查询条件
    deleFilterGroup(v) {
      let formData = new FormData();
      formData.append('id', v);
      this.$showLoading();
      // this.loading=true
      deleteFilter(formData).then((res) => {
        this.$hideLoading();
        // this.loading=false
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.getMyFilter();
        }
      });
    },
    // 获取我的查询条件
    getMyFilter() {
      myFilters().then((res) => {
        this.loadFilter = true;
        this.filterList = res.data;
      });
    },
    // 定制列
    handleColumnCommand(v) {
      if (v == '定制列组合') {
        this.cusColumnVisible = true;
        this.myColumsName = '';
        this.initCheckedColumns = this.fields.map((v) => v.keyName);
      } else {
        // console.log(v);
        // localStorage.setItem('myColumsId', v.id);
        localStorage.setItem('myColumsId_' + this.level, v.id);
        this.setColumsStorge(v.columns);
        this.myCols.myColumsId = v.id;
        this.fields = v.columns;
        this.debouncedReloadTable();
      }
    },
    // 编辑定制列
    editColumns(v) {
      this.cusColumnVisible = true;
      this.myColumsName = v.name;
      this.initCheckedColumns = v.columns.map((v) => v.keyName);
      // console.log(this.initCheckedColumns);
    },
    // 删除定制列
    deleColumns(v) {
      let formData = new FormData();
      formData.append('id', v.id);
      deleteColumn(formData).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '删除成功',
          });
          this.myColumns();
        }
      });
    },
    // 保存当前列组合
    handleGetCusColumns(arr) {
      // 重新渲染表格
      // console.log(arr);
      this.fields = arr;
      this.params.fields = arr;
      this.setColumsStorge(arr);
      this.debouncedReloadTable();
    },
    // 保存列组合，以及我的列组合
    handleGetSaveRes(res) {
      // 重新渲染定制列下拉框数据
      this.fields = res;
      this.params.fields = res;
      this.setColumsStorge(res);
      this.fields = JSON.parse(localStorage.getItem('myColums_' + this.level));
      // 重新渲染表格
      this.debouncedReloadTable();
      this.myColumns();
    },
    setColumsStorge(arr) {
      localStorage.setItem('myColums_' + this.level, JSON.stringify(arr));
    },
    // 去掉query  campaign_id 的参数
    removeQueryParam(paramToRemove) {
      let newQuery = {};
      for (let key in this.$route.query) {
        if (key !== paramToRemove) {
          newQuery[key] = this.$route.query[key];
        }
      }
      this.$router.push({ path: this.$route.path, query: newQuery });
    },
    // 关键词变化
    keywordChange(v) {
      if (!v) {
        this.removeQueryParam('campaign_id');
      }
      this.debouncedReloadTable();
    },
    // 自定义排序
    compare(propertyName, sort) {
      return function (obj1, obj2) {
        var value1 = obj1[propertyName] || 0;
        var value2 = obj2[propertyName] || 0;
        if (typeof value1 === 'string' && typeof value2 === 'string') {
          const res = value1.localeCompare(value2, 'zh');
          return sort === 'ascending' ? res : -res;
        } else {
          if (value1 <= value2) {
            return sort === 'ascending' ? -1 : 1;
          } else if (value1 > value2) {
            return sort === 'ascending' ? 1 : -1;
          }
        }
      };
    },
    manualSort(item) {
      let key = item.keyName || item.prop;
      return (
        key == 'add_payment_info' ||
        key == 'add_payment_info_cost' ||
        key == 'add_to_cart' ||
        key == 'add_to_cart_cost' ||
        key == 'add_to_cart_web' ||
        key == 'add_to_cart_web_cost' ||
        key == 'content_view_web' ||
        key == 'content_view_web_cost' ||
        key == 'credit_spent' ||
        key == 'fb_pixel_add_payment_info' ||
        key == 'fb_pixel_initiate_checkout' ||
        key == 'initiated_checkout_web_cost' ||
        key == 'initiate_checkout' ||
        key == 'initiate_checkout_cost' ||
        key == 'landing_page_view' ||
        key == 'like' ||
        key == 'link_click' ||
        key == 'photo_view' ||
        key == 'post' ||
        key == 'purchase' ||
        key == 'purchase_cost' ||
        key == 'purchase_web' ||
        key == 'purchase_web_cost' ||
        key == 'purchase_web_value' ||
        key == 'video_view' ||
        key == 'view_content' ||
        key == 'view_content_cost' ||
        key == 'local_order' ||
        key == 'effective_status'
      );
    },
    // 监听表格排序
    sortChange(v) {
      // console.log(v.direction);
      // 判断是否前端排序
      if (v.prop == 'name') {
        this.manualSortFlag = null;
        // console.log(v.prop);
      } else if (this.manualSort(v)) {
        if (v.prop == 'local_order') {
          this.tableData.sort(this.compare('localsOrderNum', v.order));
        } else if (v.prop == 'effective_status') {
          this.tableData.sort(this.compare('statusIndex', v.order));
        } else {
          this.tableData.sort(this.compare(v.prop, v.order));
        }
        this.$refs.table.doLayout();
        this.manualSortFlag = v;
      } else {
        this.manualSortFlag = null;
        let obj = {
          field: v.prop,
          direction: v.order ? (v.order == 'ascending' ? 'ASC' : 'DESC') : '',
        };
        // console.log(obj);
        this.params.sort = [obj].filter((v) => v.direction);
        this.debouncedReloadTable();
      }
    },
    // 点击创建广告类型
    handleCommandClick() {
      this.handleCommand(this.adType);
    },
    handleCommand(v) {
      // console.log('handleCommand',v);
      // console.log(this.$store);
      this.adType = v;
      if (v === '创建标准广告') {
        console.log(this.$router);
        this.createStandardAd({ name: 'CreateAd', query: this.$router.currentRoute.query });
      } else {
        this.createStandardAd({ name: 'createFastAd' });
      }
    },
    // 跳转路由
    createStandardAd(router) {
      this.$router.push(router);
    },
    // 表格表头筛选
    selectStatus(v) {
      // console.log(v);
      let flag = this.params.filtering.filter((v) => v.field == 'status').length;
      if (this.params.filtering.filter((m) => m.field == 'status').length) {
        this.params.filtering.map((item, i) => {
          if (item.field == 'status') {
            item.value1 = v;
            item.value = '';
            this.$set(this.params.filtering, i, item);
          }
        });
      } else {
        let obj = { field: 'status', operator: 'IN', value1: v, value: '' };
        this.params.filtering.push(obj);
      }
      this.saveFilterStorage();
      this.debouncedReloadTable();
      // console.log(this.params.filtering);
    },
    // 定时筛选
    selectSchedule(v) {
      this.params.filtering = this.params.filtering.filter((v) => v.field != 'open_schedule');
      let obj = { field: 'open_schedule', operator: 'EQUAL', value: v, value1: v };
      this.params.filtering.push(obj);

      this.debouncedReloadTable();
    },
    copyRow(scope) {
      // console.log('scope',scope);
      this.list = [scope.row];
      this.editCopyShow = true;
      this['audiences/setMultipleAdsets'](this.list);
      // this.showDrawer("copy", [scope.row], scope.row.level);
    },
    // 修改状态
    setStatus(scope, status) {
      // 单个删除 传scope status 批量删除 传status  批量开启暂停传 status  单个开启暂停传scope
      let params = {
        id: [],
        status: '',
        level: this.level,
      };
      if (scope) {
        let row = scope.row;
        params.level = row.level;
        (params.id = [row.id]), (params.status = status ? status : row.status);
      } else {
        params.id = this.selectedRowScopes.map((v) => v.id);
        params.status = status;
      }
      let names = this.selectedRowScopes.map((v) => v.name).join('，');
      let message = `<p>确定要操作如下广告？</p><p style="color: red;font-size:12px;max-height: 80vh;overflow: scroll">（${names}）</p>`;
      if ((scope && status) || (!scope && status)) {
        this.$confirm(message, '提示', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            // console.log(params);
            this.setStatusEvent(scope, status, params);
          })
          .catch(() => {});
      } else {
        // console.log(params);
        this.setStatusEvent(scope, status, params);
      }
    },
    setStatusEvent(scope, status, params) {
      mutateStatus(JSON.stringify(params)).then((res) => {
        // console.log(res);
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: '成功',
          });
          if (scope) {
            if (status) {
              // 单个删除
              this.clearChidren(params.id);
              this.debouncedReloadTable();
            } else {
              // 单个开启暂停
              scope.row.effective_status = scope.row.status;
              this.clearChidren(params.id);
            }
          } else {
            if (status == 'DELETED') {
              // 批量删除
              this.clearChidren(params.id);
              this.debouncedReloadTable();
            } else {
              // 批量开启暂停
              this.selectedRowScopes.map((v) => (v.status = v.effective_status = status));
              // this.clearChidren(params.id)
            }
          }
          this.clearChidren(params.id);
        } else {
          this.$message({
            type: 'warning',
            message: '失败了',
          });
          if (scope) {
            scope.row.status = scope.row.status == 'ACTIVE' ? 'PAUSED' : 'ACTIVE';
          }
        }
      });
    },
    // 清除行的子项
    clearChidren(list) {
      list.map((k) => {
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          if (v == k) {
            delete this.$refs.table.store.states.lazyTreeNodeMap[v];
          }
        });
        if (this.$refs.table.store.states.treeData[k]) {
          this.$refs.table.store.states.treeData[k].loaded = false;
          this.$refs.table.store.states.treeData[k].expanded = false;
          this.$refs.table.store.states.treeData[k].children = [];
          this.$set(this.$refs.table.store.states.treeData, k, this.$refs.table.store.states.treeData[k]);
        }
        this.tableData.map((item, index) => {
          if (item.id == k) {
            item.hasChildren = true;
            this.$refs.table.toggleRowExpansion(item, false);
            this.$set(this.tableData, index, item);
          }
        });
      });
    },
    // 批量修改竞价预算之后刷新列表部分值
    refreshTablePrice(list) {
      // console.log(list);
      this.clearChidren(list);
      let listNew = JSON.parse(JSON.stringify(list));
      listNew.map((item) => {
        // console.log(this.$refs.table.store.states);
        this.$refs.table.store.states.data.map((v, i) => {
          if (v.id == item.id) {
            item.daily_budget ? (v.daily_budget = item.daily_budget * 100) : null;
            item.lifetime_budget ? (v.lifetime_budget = item.lifetime_budget * 100) : null;
            item.bid_amount ? (v.bid_amount = item.bid_amount * 100) : null;
            item.roas_average_floor = item.roas_average_floor;
          }
        });
      });
    },
    // 刷新当前行
    refreshTableRow(scope) {
      let row = scope.row;
      // 如果存在子级重新加载子级
      console.log(this.maps);
      let obj = this.maps.get(row.id);
      console.log(obj);
      if (obj) {
        const { tree, treeNode, resolve } = this.maps.get(row.id);
        // this.$set(this.$refs.table.store.states.lazyTreeNodeMap, row.id, []);
        this.$refs.table.store.states.treeData[row.id].loaded = false;
        this.$refs.table.store.states.treeData[row.id].expanded = false;
      } else {
      }
      // this.$refs.table.store.states.treeData[row.id].loaded = false;
      // this.$refs.table.store.states.treeData[row.id].expanded = false;
      // this.$set(this.$refs.table.store.states.lazyTreeNodeMap, row.id, []);
      this.$forceUpdate();
      // this.$set(this.tableData, scope.$index, row);
    },
    // 细分选项
    selectBreakdown(v) {
      let str = '';
      for (let key in this.breakdowms) {
        if (this.breakdowms[key]) {
          str += ',' + this.breakdowms[key];
        }
      }
      this.breakdownGroup = str.split(',');
      this.breakdownGroup.map((item, index) => {
        if (!item) {
          this.breakdownGroup.splice(index, 1);
        }
      });
      this.breakdownAds(this.tableData);
    },
    // expandChange 切换表格的展开项
    expandChange(row, expanded) {
      if (
        !this.breakdownGroup.length &&
        !this.$refs.table.store.states.lazyTreeNodeMap[row.id] &&
        expanded &&
        row.level != 'ad'
      ) {
        if (this.maps.get(row.id)) {
          const { tree, treeNode, resolve } = this.maps.get(row.id);
          // this.loadChildren(tree, treeNode, resolve);
        }
      }
    },
    // 加载列表的细分项
    breakdownAds(tableList) {
      let { action_attribution_windows, date_start, date_end, fields, level } = this.params;
      let params = {
        action_attribution_windows,
        date_start,
        date_end,
        fields,
        level,
      };
      // params.breakdowns = this.breakdownGroup;
      if (this.breakdownGroup.length) {
        this.breakdownGroup.map((item, index) => {
          this.breakdownRulesArr.map((o) => {
            o.rules.map((v) => {
              // console.log(v);
              if (v.value == item && v.disabled) {
                // console.log("aaaaaaaaaaa");
                this.breakdownGroup.splice(index, 1);
                this.breakdowms[o.by] = '';
                // console.log( this.breakdownGroup);
              }
            });
          });
        });
      }
      if (this.breakdownGroup.length) {
      } else {
        this.$set(this.$refs.table.store.states, 'lazy', true);
        Object.keys(this.$refs.table.store.states.lazyTreeNodeMap).map((v) => {
          delete this.$refs.table.store.states.lazyTreeNodeMap[v];
        });
        for (let key in this.$refs.table.store.states.treeData) {
          this.$refs.table.store.states.treeData[key].loaded = false;
          this.$refs.table.store.states.treeData[key].expanded = false;
          this.$refs.table.store.states.treeData[key].children = [];
          this.$set(this.$refs.table.store.states.treeData, key, this.$refs.table.store.states.treeData[key]);
        }
        this.tableData.map((item, index) => {
          item.hasChildren = true;
          this.$refs.table.toggleRowExpansion(item, false);
          this.$set(this.tableData, index, item);
        });
        // this.$refs.table.doLayout();
        return false;
      }
      params.account_id = this.$store.getters.accountId;
      params.ids = tableList.map((item) => item.id);
      params.level = this.level;
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = [
        'name',
        'status',
        'id',
        'campaign_ref',
        'campaign_id',
        'adset_id',
        'purchase',
        'bid_strategy',
        'roas_average_floor',
        'objective',
        'optimization_goal',
      ].concat(filedList);
      params.breakdowns = this.breakdownGroup;
      // console.log(this.breakdownGroup);
      this.downLoadParams = JSON.parse(JSON.stringify(params));
      breakdown(JSON.stringify(params)).then((res) => {
        this.tableData.map((item, index) => {
          let list = res.data.data.filter((v) => v.breakdown_object_id == item.id);
          item.hasChildren = true;
          this.$set(this.tableData, index, item);
          this.$refs.table.toggleRowExpansion(item, true);
          this.$refs.table.store.states.treeData[item.id].loaded = false;
          this.$refs.table.store.states.treeData[item.id].expanded = true;
          this.$set(this.$refs.table.store.states.lazyTreeNodeMap, item.id, list);
          this.$set(this.$refs.table.store.states.treeData, item.id, this.$refs.table.store.states.treeData[item.id]);
        });
      });
    },
    // 加载广告系列子集
    loadChildren(tree, treeNode, resolve) {
      let level = '';
      this.maps.set(tree.id, { tree, treeNode, resolve });
      if (this.$refs.table.store.states.lazyTreeNodeMap[tree.id]) {
        resolve(this.$refs.table.store.states.lazyTreeNodeMap[tree.id]);
        return false;
      }
      let obj;
      if (tree.level == 'campaign') {
        level = 'adset';
        obj = { field: 'campaign.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else if (tree.level == 'adset') {
        level = 'ad';
        obj = { field: 'adset.id', operator: 'IN', value: [tree.id] };
        this.params.object_filtering.push(obj);
      } else {
        setTimeout(() => {
          resolve([]);
        }, 100);
        return false;
      }
      // this.setDateRangeParam();
      // this.infiniteScrollDisabled = true;
      let params = JSON.parse(JSON.stringify(this.params));
      // this.$hideLoading();
      // this.loading = false;
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = [
        'name',
        'status',
        'id',
        'campaign_ref',
        'campaign_id',
        'adset_id',
        'purchase',
        'bid_strategy',
      ].concat(filedList);
      params.account_id = this.$store.getters.accountId;
      params.object_filtering = [obj];
      // params.filtering = [];
      let filtering = JSON.parse(JSON.stringify(this.params.filtering));
      params.filtering = filtering.filter(
        (v) =>
          v.field != 'status' &&
          v.field != 'objective' &&
          v.field != 'ad_label' &&
          v.field != 'open_schedule' &&
          v.value !== '',
      );
      params.level = level;
      params.after = '';
      this.getList(params, true, tree).then((res) => {
        setTimeout(() => {
          resolve(res);
        }, 200);
      });
    },
    // 获取点击name传过来的id
    openNextLevel(row, level) {
      this.$refs.table.clearSelection();
      this.$refs.table.toggleRowSelection(row, true);
      this.$emit('selection-change', this.level, [row]);
      // this.$emit('select-tab',{name:level,id:[{id:row.id}]})
      this.$emit('select-tab', { name: level, row });
    },
    // 监听选择表格的选择项
    handleSelectionChange(v) {
      this.selectedRowScopes = v;
      this['audiences/setMultipleAdsets'](v);
      this.list = v;
      if (this.level == 'campaign') {
        this.$store.commit('adActiveTab/setSelectCampaignNew', v);
      }
      if (this.level == 'adset') {
        this.$store.commit('adActiveTab/setSelectAdsetsNew', v);
      }
      this.$emit('selection-change', this.level, v);
    },
    // 清除表格选择项
    clearIds() {
      this.selectedRowScopes = [];
      this.$emit('selection-change', this.level, []);
      this.$refs.table.clearSelection();
    },
    // 点击细分
    showBreakdown(v) {
      if (v && !this.loadBreakdown) {
        this.breakdownRules();
      }
    },
    // 获取表格细分项
    breakdownRules() {
      let params = {
        date_end: this.params.date_end,
        date_start: this.params.date_start,
      };
      breakdownRules(params).then((res) => {
        if (res.code == 0) {
          this.loadBreakdown = true;
          this.breakdownRulesArr = res.data;
          this.breakdownRulesArr.map((item) => {
            item.value = item.by;
            item.rules.map((item1) => {
              if (!item1.disable_when) {
                item1.disable_when = [];
              }
            });
          });
        }
      });
    },
    loadMoreClick() {
      if (this.loadText == '滚动/点击加载更多') {
        this.loadMore();
      }
    },
    // 下拉加载更多
    loadMore() {
      if (this.infiniteScrollDisabled) {
        return;
      }
      console.log(this.params);
      let params = JSON.parse(JSON.stringify(this.params));
      let filedList = this.fields.map((v) => v.keyName);
      params.fields = [
        'name',
        'status',
        'id',
        'campaign_ref',
        'campaign_id',
        'adset_id',
        'purchase',
        'bid_strategy',
      ].concat(filedList);
      params.account_id = this.$store.getters.accountId;
      console.log(params, 'paramsparamsparamsparamsparams');
      params.object_filtering = params.object_filtering.filter(
        (v) => v.field != 'adset.id' && v.field != 'campaign.id',
      );
      // 处理筛选条件
      let filtering = JSON.parse(JSON.stringify(params.filtering)).filter(
        (v) => (v.value !== '' || v.value1.length) && v.field && v.operator,
      );

      filtering.map((item) => {
        if (item.field == 'status' || item.field == 'objective' || item.field == 'ad_label') {
          item.value = JSON.parse(JSON.stringify(item.value1));
        }
      });
      params.object_filtering = filtering
        .filter(
          (v) =>
            v.field == 'status' ||
            (v.field == 'objective' && v.value !== '' && v.value.length) ||
            (v.field == 'ad_label' && v.value !== '' && v.value.length) ||
            v.field == 'open_schedule',
        )
        .concat(this.objectFilteringBySection());
      params.filtering = filtering.filter(
        (v) =>
          v.field != 'status' &&
          v.field != 'objective' &&
          v.field != 'ad_label' &&
          v.field != 'open_schedule' &&
          v.value !== '',
      );

      let data = Object.assign({}, params, {
        after: this.after,
        level: this.level,
      });

      this.loadText = '努力加载中...';
      this.downLoadParams = JSON.parse(JSON.stringify(data));
      this.getList(data).then(async (res) => {
        res.forEach((item) => {
          this.tableData.push(item);
        });
        // 是否是前端排序
        if (this.manualSortFlag) {
          this.sortChange(this.manualSortFlag);
        }
        if (!params.sort.length) {
          this.tableData.sort(this.compare('statusIndex', 'ascending'));
        }
        if (res.length < this.params.page_length) {
          this.loadText = '没有更多数据了';
          this.infiniteScrollDisabled = true;
        } else {
          this.loadText = '滚动/点击加载更多';
          this.infiniteScrollDisabled = false;
        }
        this.$refs.table.doLayout();
      });
    },
    // 获取列表
    getList(data, isChild, campaginInfo) {
      let params = data;
      params.fields = params.fields.concat(this.specialFields(params));
      if (this.activeLevel == 'adset') {
        params.hasAdActive = this.hasAdActive;
      }
      this.cancelQuest();
      this.infiniteScrollDisabled = true;
      this.listLoading = true;
      //   处理定时参数改为布尔。本来是数组
      let lastParams = JSON.parse(JSON.stringify(params));
      lastParams.object_filtering.forEach((item) => {
        if (item.field == 'open_schedule' && item.value) {
          item.value = item.value.join('');
        }
      });
      return report(JSON.stringify(lastParams), this).then((res) => {
        this.listLoading = false;
        this.infiniteScrollDisabled = false;
        if (res.code != 0) {
          return [];
        }
        if (params.level == this.level && res.data.paging.after) {
          this.after = res.data.paging.after;
        }
        if (this.level == 'ad' && this.$refs.table) {
          this.$set(this.$refs.table.store.states, 'lazy', false);
        } else {
          this.$set(this.$refs.table.store.states, 'lazy', true);
        }
        res.data.data.map((v, index) => {
          // 给每一列加上本地订单数量,状态索引，方便本地排序
          v.localsOrderNum = v.local_order?.orderCount || 0;
          v.statusIndex = v.effective_status ? getIndex(v.effective_status, v.level) : 100;
          if (v.level != 'ad') {
            v.hasChildren = true;
          } else {
            v.hasChildren = false;
          }
          // 经营分析
          if (v.level == 'adset') {
            v.adProductReport = this.getAdsetReport(v, isChild, campaginInfo);
          }
        });
        // 如果存在细分项，需要继续加载细分
        if (this.breakdownGroup.length && res.data.data.length) {
          this.breakdownAds(res.data.data);
        }
        // this.$refs.table.doLayout();
        this.summary = res.data.summary;
        return res.data.data ? res.data.data : [];
      });
    },
    formatterTime(timeStr) {
      return moment(timeStr).format('HH:mm');
    },
    fromNow(time) {
      const start = moment(time, 'YYYY-MM-DD HH:mm:ss');
      const end = moment();
      const diff = end.diff(start);
      return moment.duration(diff).asMinutes();
    },
    // 获取标签列表
    async getLabelList(v) {
      await queryConfigLabel().then((res) => {
        if (res.code != 0) return;
        this.$store.commit('adActiveTab/setCampaignLabel', res.data || []);
        if (v) {
          this.cusFilterList.forEach((item) => {
            if (item.field == 'ad_label') {
              item.value1 = item.value1.filter((k) => k != v);
              if (item.value) {
                item.value = item.value.filter((k) => k != v);
              }
            }
          });
          this.params.filtering.forEach((item) => {
            if (item.field == 'ad_label') {
              item.value1 = item.value1.filter((k) => k != v);
              item.value = item.value.filter((k) => k != v);
            }
          });
        }
      });
    },
    // 添加标签弹窗
    addAdLabel() {
      this.addAdLabelShow = true;
    },
    operationLog(data) {
      this.operationData = data;
      this.showLog = true;
    },
  },
  created() {
    let filteringStr = localStorage.getItem(`${this.level}_filter`);
    if (filteringStr && JSON.parse(filteringStr).length) {
      this.params.filtering = JSON.parse(filteringStr);
      this.filterShow = true;
    }
    if (this.level == 'campaign') {
      let campaignId = this.$route.query.campaign_id;
      this.params.keyword = campaignId ? campaignId : this.params.keyword;
      this.searchInput = !!this.params.keyword;
    }
    // this.params.keyword = this.params.keyword;
    this.debouncedReloadTable = _.debounce(this.reloadTable, 500);
    this.$store.commit('adActiveTab/setAdsetDateRangNew', this.dateRange);
    this.$store.commit('adActiveTab/setAdsetDateRangOld', this.dateRange);
    this.$store.commit('adActiveTab/setAdDateRangNew', this.dateRange);
    this.$store.commit('adActiveTab/setAdDateRangOld', this.dateRange);
  },
};
</script>

<style lang="scss">
.rule-tip {
  .rule-score-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.color9 {
  color: #999;
  font-size: 12px;
}

.ACTIVE {
  background: #67c23a;
}

.PAUSED,
.IN_PROCESS {
  background: #e6a23c;
}

.nameDELETED,
.nameARCHIVED {
  @extend .color9;
}

.DELETED {
  background: #f56c6c;
}

.DISAPPROVED {
  background: #ff0000;
}

.PREAPPROVED,
.ARCHIVED {
  background: #409eff;
}

.DELETED,
.WITH_ISSUES,
.PENDING_BILLING_INFO,
.ADSET_PAUSED {
  background: #f56c6c;
}

.PENDING_REVIEW,
.CAMPAIGN_PAUSED {
  background: #909399;
}

.adManagement-container .el-tabs__content {
  padding: 0;
}

.el-icon-info {
  color: #999;
  margin-left: 10px;
}

.el-icon-warning {
  color: #e6a23c;
}

.el-icon-error {
  color: #f56c6c;
}

.adManagement-container {
  .el-table .caret-wrapper {
    width: 0;
  }
}

.toolsCheckTips {
  .el-checkbox,
  .el-radio {
    line-height: 36px;
  }
}

.dropselect {
  .el-select-dropdown__item {
    padding: 0;

    span {
      display: block;
      padding: 0 20px;
    }
  }
}

.el-table__body-wrapper {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}

.statusWarp {
  display: flex;
  align-items: center;

  .pointer {
    flex: 8px 0 0;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }

  .c6D0 {
    color: #66dd00;
  }

  .cF80 {
    color: #ff8800;
  }

  .cF33 {
    color: #ff3333;
  }
}

.breakdownList {
  li {
    line-height: 26px;
  }
}

.cusFilter {
  padding: 0 10px 0 20px;

  .cusFilterList {
    .cusFilterItem {
      margin-bottom: 10px;
    }
  }
}

.el-dropdown-menu__item {
  &.active {
    background: #ecf5ff;
    color: #66b1ff;
  }

  .columnName {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
      p {
        display: block;
      }
    }

    div {
      p {
        flex: 60px 0 0;
        display: none;

        i {
          margin: 0 5px;
          font-size: 14px;
          font-weight: bold;
        }
      }
    }
  }
}

.tagInput {
  width: 30px;

  input {
    width: auto;
    border: none;
    outline: none;
    background: transparent;
    padding: 0 5px;
    width: 30px;
    border-bottom: 1px dashed #fff;
    color: #fff;
    height: 18px;
    line-height: 18px;
  }
}

.el-popover {
  min-width: 100px;
}

.drawerCon {
  height: calc(100vh - 150px);
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
  }
}

.tableWrap {
  .el-table .cell {
    line-height: unset;
  }
}

// 状态
.header-status {
  .cell {
    display: flex !important;
    align-items: center;
  }

  .caret-wrapper {
    top: 2px;
  }
}

.my-table.el-table .el-table__body-wrapper {
  height: calc(100vh - 300px);
  overflow: auto;
}

.el-table__fixed-body-wrapper {
  height: calc(100vh - 317px);
  overflow: auto;
}
</style>
<style lang="scss" scoped>
.toolsBar {
  margin: 10px 0;
  background: rgb(232, 241, 255);
  line-height: 32px;
  height: 42.38px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #409eff;
  font-size: 12px;

  .close {
    cursor: pointer;
  }

  .toolsCon {
    display: flex;
    align-items: center;

    i {
      font-size: 16px;
    }

    p {
      background: #409eff;
      line-height: 24px;
      border-radius: 14px;
      padding: 0 10px;
      font-size: 12px;
      color: #fff;
      margin-right: 10px;
    }

    //   .toolsOne{
    //       margin: 0 5px;
    //       width: 45px;
    //       text-align: center;
    //       position: relative;
    //       display: inline-block;
    //       vertical-align: middle;
    //       .el-dropdown{
    //           height: 8px;
    //       }
    //       span{
    //           display: inline-block;
    //           position: absolute;
    //           top: 50%;
    //           left: 50%;
    //           text-align: center;
    //           width: 100%;

    //           transform: translate3d(-50%,-50%,0);
    //           i{
    //               line-height: 32px;
    //           }
    //       }
    //       .el-button{
    //           display: none;
    //       }
    //       &:hover{
    //         span{
    //             display: none;
    //         }
    //         .el-button{
    //             display: inline-block;
    //             position: absolute;
    //             top: 50%;
    //             left: 50%;
    //             margin-left: 0;
    //             // width: 100%;
    //             // padding: 5px 15px;
    //             transform: translate3d(-50%,-50%,0);
    //         }
    //       }
    //   }
  }
}

.tableWrap {
  padding: 16px;
  padding-top: 0;

  .loadMore {
    text-align: center;
    line-height: 28px;
    color: #999;
  }
}

.el-table .caret-wrapper {
  width: 0;
}

.adManagement-container {
  width: 100%;
  height: calc(100vh - 82px);
  background: #fff;

  .filterWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #ecf5ff;

    .el-image {
      flex: 14px 0 0;
      margin-left: 5px;
    }

    .paramsFilters {
      flex: 1;
      padding: 5px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      .el-tag {
        margin: 3px;
        display: flex;
        align-items: center;

        .checkbox-group {
          padding: 0 10px;

          .el-checkbox {
            display: block;
          }
        }
      }
    }

    .rightIcon {
      flex: 40px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      i {
        font-size: 15px;
        font-weight: bold;
        margin: 0 5px;
        cursor: pointer;
        color: #666;
      }
    }
  }

  .el-tabs__item {
    .el-button {
      padding: 4px 10px;
      margin-left: 5px;
    }
  }

  .table-top-bar {
    // position: absolute;
    // top: 56px;
    display: flex;
    align-items: center;
    width: 100%;
    // height: 32px;
    padding: 10px;
    padding-left: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .rightTools {
      flex: 260px 0 0;
      display: flex;
      align-items: center;
      text-align: right;
      justify-content: flex-end;

      .tools {
        margin-left: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .toolsItem {
          margin: 0 4px;
        }
      }

      .searchInput {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .el-button {
          color: #666;
          font-size: 14px;
          margin: 0 5px;
        }
      }
    }

    .selectHasAd {
      background: #fafafa;
      border-radius: 30px;
      margin-left: 10px;

      ::v-deep .el-radio-button:first-child .el-radio-button__inner {
        border-radius: 30px 0 0 30px;
        height: 34px;
        font-size: 12px;
      }

      ::v-deep .el-radio-button:last-child .el-radio-button__inner {
        border-radius: 0 30px 30px 0;
        height: 34px;
        font-size: 12px;
      }

      ::v-deep .el-radio-button__inner {
        background: #fafafa;
        border: none;
        color: #999999;
      }

      ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        color: #333333;
        border: 1px solid #f0f0f0;
        // background: #409EFF;
        border-radius: 30px;
        box-shadow: none;
        line-height: 10px;
        // color: #ffffff;
      }
    }

    .midFilter {
      flex: 1;
      padding: 0 10px;
    }

    .table-top-bar-right {
      flex: 1 1 0%;
      display: flex;
      align-items: center;
      position: relative;
      transition: all 0.3s linear 0s;

      // padding-right: 140px;
      .filter-btn {
        display: flex;
        align-items: center;
        height: 32px;
        font-size: 12px;
        color: #8c8c8c;
        margin-left: 12px;
        padding: 0 4px;
        border-radius: 2px;
        transition: all 0.3s linear 0s;
        cursor: pointer;

        img {
          margin-right: 4px;
        }
      }

      .filter-btn:hover {
        background: #eaeaed;
      }

      .filter-operation {
        position: absolute;
        right: 0;
        display: flex;
        align-items: center;
        width: auto;
        padding-left: 10px;
        transition: all 0.3s linear 0s;

        .filter-operation-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          transition: all 0.3s linear 0s;
          cursor: pointer;

          i {
            color: gray;
          }
        }

        .filter-operation-item:hover {
          background: #eaeaed;
        }
      }
    }
  }
}

.AIRecommendation {
  font-size: 12px;

  .c80D580 {
    color: #80d580;
  }

  .cFB6 {
    color: #fb6;
  }

  .cF88 {
    color: #f88;
  }

  .c99bbff {
    color: #99bbff;
  }

  .cBBB {
    color: #bbbbbb;
  }

  img {
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
}

// 批量操作的图标去掉直接展示文字
.operate-btn {
  margin-right: 5px;
}
</style>
